import React, { useMemo, useState, useEffect } from "react";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { /* MdOutlineRefresh, */ MdOutlineSearch } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context";
import { Toaster } from "sonner";
import {
  Featured,
  FilterByBrand,
  FilterByCategory,
  FilterBySize,
  Footer,
  Inputs,
  NavLinkWithLocale,
  SearchColorCard,
  SelectedOption,
} from "../../components";
import {
  Alert,
  sponsors,
  useBalloonsV2,
  useFilterByBrand,
  useFilterByCategory,
  useScreenSize,
  useSizeFilter,
} from "../../utils";
import style from "./SearchTool.module.css";

const SearchTool = () => {
  const { user } = useAppContext();
  const screenSize = useScreenSize();
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [lastSearch, setLastSearch] = useState("");
  const [lastValidResults, setLastValidResults] = useState([]);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [filteredBalloons, setFilteredBalloons] = useState([]);
  const { t } = useTranslation();
  const { data: balloonsData, isLoading: loadingBalloons } = useBalloonsV2();

  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  useEffect(() => {
    if (!searchTriggered) return;
    
    if (!lastSearch.trim()) {
      Alert.error(
        t("No results found. Try a different term or adjust your filters.")
      );
      setFilteredBalloons([]);
      setResults([]);
      setLastValidResults([]);
      setSearchTriggered(false);
      return;
    }
    
    const newFilteredBalloons = balloonsData?.filter((balloon) => {
      // Dividimos la búsqueda en palabras clave
      const searchTerms = lastSearch.toLowerCase().trim().split(/\s+/);
      return searchTerms.every((term) => {
        // Expresión regular para buscar la palabra completa
        const regex = new RegExp(`\\b${term}\\b`, "i");

        // Comparamos cada término con las propiedades relevantes
        const nameMatch = regex.test(balloon.name?.toLowerCase() || "");
        const displayNameMatch = regex.test(
          balloon.displayName?.toLowerCase() || ""
        );
        const colorCategoriesMatch = (balloon.colorCategories ?? []).some(
          (category) => regex.test(category?.toLowerCase() || "")
        );

        const brandMatch = balloon.combined
          ? regex.test(balloon.insideBrand?.toLowerCase() || "") ||
          regex.test(balloon.outsideBrand?.toLowerCase() || "")
          : regex.test(balloon.brand?.toLowerCase() || "");

        // El globo solo se muestra si TODOS los términos están en alguna propiedad
        return (
          nameMatch || displayNameMatch || colorCategoriesMatch || brandMatch
        );
      });
    });
    setFilteredBalloons(newFilteredBalloons);
    if (!lastSearch || !newFilteredBalloons.length) {
      Alert.error(
        t("No results found. Try a different term or adjust your filters.")
      );
    }
  
    setResults(newFilteredBalloons);
    setLastValidResults(newFilteredBalloons);
    setSearchTriggered(false); // Reinicia el estado para futuras búsquedas
  }, [lastSearch, searchTriggered, balloonsData]);

  // Aplicación de filtros adicionales
  const { brandsSelected, handleBrandsChange, clearAllBrands, filteredBrands } =
    useFilterByBrand(filteredBalloons);
  const { sizesSelected, handleSizesChange, clearAllSizes, filteredSizes } =
    useSizeFilter(filteredBalloons);
  const {
    categoriesSelected,
    handleCategoryChange,
    clearAllCategories,
    filteredCategories,
  } = useFilterByCategory(filteredBalloons);

  const options = useMemo(() => {
    let result = lastValidResults;

    const hasBrandFilter = Object.values(brandsSelected).includes(false);
    const hasSizeFilter = Object.values(sizesSelected).includes(false);
    const hasCategoryFilter = Object.values(categoriesSelected).includes(false);

    if (hasBrandFilter) result = filteredBrands;
    if (hasSizeFilter)
      result = result?.filter((item) => filteredSizes.includes(item));
    if (hasCategoryFilter)
      result = result?.filter((item) => filteredCategories.includes(item));

    return result;
  }, [
    lastValidResults,
    brandsSelected,
    filteredBrands,
    sizesSelected,
    filteredSizes,
    categoriesSelected,
    filteredCategories,
  ]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleOnClickSearch = () => {
    setLastSearch(searchValue.trim().toLowerCase());
    setSearchTriggered(true); // Indicamos que la búsqueda debe ejecutarse
  };

  /* const handleReset = () => {
    setResults([]);
    setLastValidResults([]);
    setError(false);
    setSearchValue("");
  }; */

  // const balloonsToDisplay = options?.length > 0 ? results : lastValidResults;

  const renderFilterOption = (name) => {
    if (brandsSelected[name]) {
      return (
        <Col key={name} xs="auto">
          <SelectedOption
            id={name}
            name={name}
            deleteFilter={handleBrandsChange}
          />
        </Col>
      );
    }

    if (sizesSelected[name]) {
      return (
        <Col key={name} xs="auto">
          <SelectedOption
            id={name}
            name={name}
            deleteFilter={handleSizesChange}
          />
        </Col>
      );
    }

    if (categoriesSelected[name]) {
      return (
        <Col key={name} xs="auto">
          <SelectedOption
            id={name}
            name={name}
            deleteFilter={handleCategoryChange}
          />
        </Col>
      );
    }

    return null;
  };

  const clearFilters = () => {
    clearAllBrands();
    clearAllSizes();
    clearAllCategories();
  };
  const filtersObject = {
    ...brandsSelected,
    ...sizesSelected,
    ...categoriesSelected,
  };
  const hasAnyTrue = Object.values(filtersObject).some((value) => value);

  return (
    <>
      <Container fluid>
        <Row
          className={`justify-content-center align-items-center pt-4 ${style.searchBanner}`}
        >
          <Col sm={12} md={10} lg={5}>
            <h1>{t("Search Balloons")}</h1>
            <p>
              {t("Your all-in-one reference")}
              {!results.length &&
                t(" — quickly find colors and sizes across leading balloon brands.")}
            </p>
          </Col>
        </Row>
        <Row
          className={`justify-content-center align-items-center pb-4 ${style.searchBanner}`}
        >
          <Col sm={10} md={8} xl={6} className="mb-4 px-md-5">
            <Inputs.FormControl
              value={searchValue}
              onChange={handleSearch}
              customClass={style.searchBarLabel}
              className={style.searchBar}
              icon={screenSize > 992 && <MdOutlineSearch />}
              placeholder={t("Search by balloon color or brand...")}
              size="lg"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleOnClickSearch();
                }
              }}
              decoration={
                // <>
                <Button
                  variant="light"
                  className={style.searchBtn}
                  onClick={handleOnClickSearch}
                  size="md"
                >
                  {screenSize > 992 ? (
                    loadingBalloons ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      t("SEARCH")
                    )
                  ) : (
                    <MdOutlineSearch size="1.5rem" />
                  )}
                </Button>
                // {lastSearch && (
                //   <Button
                //     variant="light"
                //     className={style.searchBtn}
                //     onClick={handleReset}
                //     size="md"
                //   >
                //     <MdOutlineRefresh size="1.5rem" />
                //   </Button>
                // )}
                // </>
              }
            />
          </Col>
        </Row>
        {!results.length && (
          <Row className="justify-content-around mt-4">
            <h2 className={style.sponsorsTitle}>
              Brands currently supported in our search results
            </h2>
            {sponsors.map((sponsor) => (
              <Col xs={3} md={2} lg={1} key={sponsor.name}>
                <Image
                  width="100%"
                  src={sponsor.image}
                  alt={sponsor.name}
                  className={style.sponsorImage}
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
      <Container className="my-5">
        <Row>
          {!results.length && (
            <Col xs={12}>
              <Featured />
            </Col>
          )}
          <Col xs={12}>
            {lastValidResults.length > 0 && (
              <>
                <Row className="p-0 m-0 justify-content-between justify-content-lg-start">
                  <p className={`mt-4 p-0 ${style.resultsCount}`}>
                    {options.length} {t("Results for")} "{lastSearch}"
                  </p>
                  <Col xs="auto" className="p-0 pe-lg-3">
                    <FilterByBrand
                      brandsSelected={brandsSelected}
                      handleChange={handleBrandsChange}
                      clearAll={clearAllBrands}
                      variant="light"
                      customClass={style.filters}
                    />
                  </Col>
                  <Col xs="auto" className="p-0 pe-lg-3">
                    <FilterBySize
                      sizesSelected={sizesSelected}
                      handleChange={handleSizesChange}
                      clearAll={clearAllSizes}
                      variant="light"
                      customClass={style.filters}
                    />
                  </Col>
                  <Col xs="auto" className="p-0 pe-lg-3">
                    <FilterByCategory
                      categoriesSelected={categoriesSelected}
                      handleChange={handleCategoryChange}
                      clearAll={clearAllCategories}
                      variant="light"
                      customClass={style.filters}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-between">
                  <Col xs={10}>
                    <Row>
                      {Object.keys(filtersObject)
                        .filter((name) => filtersObject[name])
                        .map((name) => renderFilterOption(name))}
                    </Row>
                  </Col>
                  {/* {hasAnyTrue && (
                    <Col xs={2} style={{ textAlign: "center" }}>
                      <Button
                        onClick={clearFilters}
                        className={style.clearFilters}
                      >
                        Clear Filters
                      </Button>
                    </Col>
                  )} */}
                </Row>
              </>
            )}
            {lastValidResults.length > 0 && (
              <Row>
                {options?.map((balloon) => (
                  <Col key={balloon.id} xs={6} md={4} lg={2} className="my-2">
                    <SearchColorCard key={balloon.color} balloon={balloon} />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
      {!user && (
        <Container fluid className={`py-5 ${style.searchBottomBanner}`}>
          <Row className={`justify-content-around`}>
            <Col xs={12} md={6} className={style.bottomBannerText}>
              <h2>{t("Find your Balloon Match")}</h2>
              <p>
                {t(
                  "Have an inspiration image and need to find the right balloons? Upload a photo, click on a color, and get the closest match in seconds. Try it now!"
                )}
              </p>
            </Col>
            <Col xs="auto" className="mt-sm-5 mt-md-0">
              {!user ? (
                <NavLinkWithLocale to="/createAccount">
                  <Button size="lg" className={style.footerBannerBtn}>
                    {t("SIGN UP FOR FREE")}
                  </Button>
                </NavLinkWithLocale>
              ) : (
                !userPro && (
                  <NavLinkWithLocale to="/createAccount">
                    <Button size="lg" className={style.footerBannerBtn}>
                      {t("SIGN UP FOR PRO")}
                    </Button>
                  </NavLinkWithLocale>
                )
              )}
              <p className={style.announce}>{t("No credit card required.")}</p>
            </Col>
          </Row>
        </Container>
      )}
      <Toaster richColors position="bottom-center" />
      <Footer />
    </>
  );
};

export default SearchTool;
