import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { FiDownload, FiPlusCircle, FiShare } from "react-icons/fi";
import { RiFolderSharedLine } from "react-icons/ri";
import { FaStar } from "react-icons/fa";
import { useAppContext, useColorMatchContext } from "../../context";
import { DownloadBtn, SaveBtn, ShareBtn, UpgradeToProModal } from "../index";
import style from "./Commands.module.css";

const ActionCommands = () => {
  const { images, setCreateNewPaletteModal, user } = useAppContext();
  const { colormatch } = useColorMatchContext();
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState({
    save: false,
    download: false,
    share: false,
  });
  const [showProVersionModal, setShowProVersionModal] = useState(false);
  const [userProVersion, setUserProVersion] = useState(false);

  useEffect(() => {
    if (user) {
      if (
        user.stripeSubscriptionStatus === "active" ||
        user.stripeSubscriptionStatus === "trialing"
      ) {
        setUserProVersion(true);
      } else {
        setUserProVersion(false);
      }
    }
  }, [user]);

  const handleCreateNewPalette = () => {
    setCreateNewPaletteModal(true);
  };

  return (
    <>
      <Col
        lg={{ order: 0, span: 12 }}
        className={`${style.actionCommandsContainer}`}
      >
        <Row
          className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}
        >
          <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
            {userProVersion ? (
              <SaveBtn overlay={overlay.save} setOverlay={setOverlay} />
            ) : (
              <Button
                variant="secondary"
                className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
                disabled={colormatch.length > 0 ? false : true}
                onClick={() => setShowProVersionModal(true)}
              >
                <RiFolderSharedLine />
                {t("Save")}
                <FaStar color="#FF6563" style={{ marginLeft: ".3rem" }} />
              </Button>
            )}
          </Col>
          <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
            {userProVersion ? (
              <ShareBtn overlay={overlay.share} setOverlay={setOverlay} share />
            ) : (
              <Button
                variant="secondary"
                className={`${style.actionBtn} ${overlay && style.onTopBtn} `}
                disabled={colormatch.length > 0 ? false : true}
                onClick={() => setShowProVersionModal(true)}
              >
                <FiShare />
                {t("Share")}
                <FaStar color="#FF6563" style={{ marginLeft: ".3rem" }} />
              </Button>
            )}
          </Col>
          <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
            {userProVersion ? (
              <DownloadBtn overlay={overlay.download} setOverlay={setOverlay} />
            ) : (
              <Button
                variant="secondary"
                className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
                disabled={colormatch.length > 0 ? false : true}
                onClick={() => setShowProVersionModal(true)}
              >
                <FiDownload />
                {t("Download")}
                <FaStar color="#FF6563" style={{ marginLeft: ".3rem" }} />
              </Button>
            )}
          </Col>
          <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
            <Button
              variant="secondary"
              onClick={handleCreateNewPalette}
              size="sm"
              className={style.actionBtnCNP}
              disabled={colormatch.length === 0 && images.length === 0}
            >
              <FiPlusCircle />
              {t("Create New Palette")}
            </Button>
          </Col>
        </Row>
        {(overlay.share || overlay.save || overlay.download) && (
          <div className={style.overlay} />
        )}
      </Col>

      <UpgradeToProModal
        setShowProVersionModal={setShowProVersionModal}
        showProVersionModal={showProVersionModal}
      />
    </>
  );
};

export default ActionCommands;
