import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import { NavLinkWithLocale, Footer, Scroller } from "../../components";
import { TextsSlider } from "../Pricing/TextsSlider";
import style from "./Home.module.css";
import { homeCards, homeCardsEs, useScreenSize } from "../../utils";

const Home = () => {
  const { user } = useAppContext();
  const { t, i18n } = useTranslation();
  const screenSize = useScreenSize();
  const [homeCardsLocale, setHomeCardsLocale] = useState([]);

  useEffect(() => {
    if (i18n.language === "en") {
      setHomeCardsLocale(homeCards);
    } else {
      setHomeCardsLocale(homeCardsEs);
    }
  }, [i18n.language]);

  return (
    <Container className={`p-0 m-0 ${style.homeContainer}`} fluid>
      <Row
        className={`py-2 py-md-5 m-0 gap-md-0 justify-content-around align-items-center ${style.homeSec1}`}
      >
        <Col xs={12} md={5} lg={5} className={`mt-4 ${style.homeIntro}`}>
          <span className={style.homePreTitle}>{t("“A MUST-HAVE TOOL”")}</span>
          <h1 className={`mt-3 mb-2 ${style.homeTitle}`}>
            {t("Build your balloon color palette in seconds!")}
          </h1>
          <p className={style.homeSubtitle}>
            {t("Match colors from any image")}
            {screenSize < 768 && <br />}{" "}
            {t("and create stunning balloon palettes.")}
          </p>
          {!user && (
            <NavLinkWithLocale to="/createAccount" className="mt-4">
              <Button size="lg" className={style.homeBtn}>
                {t("SIGN UP FOR FREE")}
              </Button>
            </NavLinkWithLocale>
          )}
        </Col>
        <Col
          xs={11}
          sm={9}
          md={6}
          lg={5}
          className={`my-4 my-md-0 ${style.heroImgContainer}`}
        >
          <Image
            src={require("../../assets/BCM-color-palette-builder.png")}
            width="100%"
          />
        </Col>
      </Row>
      <h2 className={`mt-5 ${style.homeSec2Title}`}>
        {t("Over 1000+ balloon colors from top leading brands")}
      </h2>
      <Scroller />
      <Row className={`mx-0 justify-content-center ${style.homeSec3}`}>
        <h2 className={style.homeSec3Title}>
          {t("How it Works and Why You’ll Love It")}
        </h2>
        <p className={`mb-4 ${style.homeSec3Subtitle}`}>{t("sec3Subtitle")}</p>
          <Row className="justify-content-center mt-4">
            {homeCardsLocale.map((card, index) => (
              <Col key={index} xs={10} sm={4} md={3} lg={4} className="mt-4 text-center">
                <Image src={card.icon}
                  alt={`${card.title} icon`}
                  className={`${style.homeCardImg}`} 
                />

                <h5 className={`mt-3 mb-1 ${style.homeCardTitle}`}>
                  {card.title}
                </h5>

                <p className={style.homeCardText}>{card.text}</p>
              </Col>
            ))}
          </Row>

        <Row className="justify-content-center">
          <Col xs={12} sm={10} lg={8}>
            <Image
              width="100%"
              src={require("../../assets/balloon-color-match-homepage-hero.gif")}
            />
          </Col>
        </Row>
      </Row>
      <Row
        className={`pt-4 py-md-5 m-4 gap-md-0 justify-content-around align-items-center ${style.homeSec4}`}
      >
        <Col xs={12} md={5} className={`py-4 ${style.homeIntro4}`}>
          <h2 className={style.homeTitle}>{t("Balloon Finder")}</h2>
          <p className={style.homeSubtitle}>
            {t(
              "Browse balloon brands, view colors, and check available sizes—all in one place!"
            )}
          </p>
          <NavLinkWithLocale
            to="/search"
            className={`mt-4 ${style.exploreLink}`}
          >
            <Button size="lg" className={`px-5 ${style.homeSec4Btn}`}>
              {t("SEARCH BALLOONS")}
            </Button>
          </NavLinkWithLocale>
        </Col>
        <Col xs={12} sm={9} md={6} className={`my-4 my-md-0`}>
          <Image
            src={require("../../assets/Explore Colors-image.png")}
            width="100%"
          />
        </Col>
      </Row>
      <TextsSlider />
      {!user && (
        <Row
          className={`m-0 align-items-center justify-content-around ${style.homeJoinUs}`}
        >
          <Col md={5} lg={6}>
            <h3 className={style.homeTitle}>{t("Join Us")}</h3>
            <p className={style.homeSubtitle}>{t("joinUsCTA")}</p>
          </Col>
          <Col xs={12} sm={8} md={5} xl={3} className={style.homeIntro}>
            <NavLinkWithLocale to="/createAccount">
              <Button size="lg" className={style.homeBtn}>
                {t("SIGN UP FOR FREE")}
              </Button>
            </NavLinkWithLocale>
            <p className={style.announce}>{t("No credit card required.")}</p>
          </Col>
        </Row>
      )}
      <Footer />
    </Container>
  );
};

export default Home;
