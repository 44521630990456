import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { app } from "../../firebase";
import { Toaster } from "sonner";
import { NavLinkWithLocale } from "../../components";
import { Alert } from "../../utils";
import style from "./Login.module.css";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { checkUserExistsInFirestore } from "../../utils/auxiliarFunctions";

const Login = () => {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loginFields, setLoginFields] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFormControl = (e) => {
    const { name, value } = e.target;
    setLoginFields({ ...loginFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loginFields.email || !loginFields.password)
      return Alert.error("Fields must be completed");
    setLoading(true);
    try {
      await signInWithEmailAndPassword(
        auth,
        loginFields.email,
        loginFields.password
      );
      localStorage.setItem("userLoggedIn", true);
      setLoading(false);
      navigate(`/${i18n.language}/colormatch`);
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/user-not-found") {
        Alert.error("This Color Match account doesn't exist");
      }
      if (error.code === "auth/invalid-credential") {
        Alert.error("The credentials are invalid. Please try again");
      }

      //return Alert.error(error.code);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginWithGoogle = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = `${process.env.REACT_APP_TEST_DOMAIN}/en/login`;
    const scope = "profile email openid";
    const nonce = Math.random().toString(36).substring(2);

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token id_token&scope=${scope}&nonce=${nonce}`;

    window.location.href = authUrl;
  };

  /* const handleLoginWithFacebook = ()=>{
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const redirectUri = `${process.env.REACT_APP_TEST_DOMAIN}/en/login`;
    const scope = "email,public_profile";
    const state = Math.random().toString(36).substring(2);

    const authUrl = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&response_type=code`;

    window.location.href = authUrl;
  } */

  //FACEBOOK
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    let isUserExist;

    if (code) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL_V2}/api/facebook-exchange-token`,
          {
            code: code,
            redirectUri: `${process.env.REACT_APP_TEST_DOMAIN}/en/login`,
            login: true,
          }
        )
        .then((response) => {
          const { accessToken, userExist } = response.data;
          if (!userExist) {
            isUserExist = false;
          } else {
            isUserExist = true;
          }
          if (!accessToken) {
            throw new Error("No se recibió un accessToken de Facebook");
          }

          const credential = FacebookAuthProvider.credential(accessToken);
          return signInWithCredential(auth, credential).then((result) =>
            result.user.getIdToken()
          );
        })
        .then((firebaseIdToken) => {
          return axios.post(
            `${process.env.REACT_APP_BACKEND_URL_LOCAL}/api/facebook-exchange-token`,
            {
              idToken: firebaseIdToken,
              redirectUri: `${process.env.REACT_APP_LOCAL_DOMAIN}/en/login`,
              login: true,
            }
          );
        })
        .then(() => {
          if (isUserExist) {
            localStorage.setItem("userLoggedIn", true);
            setLoading(false);
            navigate(`/${i18n.language}/colormatch`);
          } else {
            Alert.error("User does not exist. Please sign up first.");
          }
        })
        .catch((error) => {
          //console.error("Error autenticando con Facebook:", error);
          if (error.code === "auth/account-exists-with-different-credential") {
            Alert.error(
              "Another method was used to log in with this account. Please try again."
            );
            return;
          }
          if (!isUserExist) {
            Alert.error("User does not exist. Please sign up first.");
            return;
          }
          Alert.error("Error authenticating with Facebook. Please try again");
        });
    } else {
      console.log("No se encontró el código en la redirección (Facebook)");
    }
  }, [navigate]);

  //GOOGLE
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1)); // Para fragmentos (#)
    const idToken = params.get("id_token");

    if (idToken) {
      const credential = GoogleAuthProvider.credential(idToken);

      const decodedToken = JSON.parse(atob(idToken.split(".")[1])); // Decodificar para obtener mail
      const emailFromGoogle = decodedToken.email;

      checkUserExistsInFirestore(emailFromGoogle)
        .then((exists) => {
          if (exists) {
            // Proceder con la autenticación en Firebase Auth
            signInWithCredential(auth, credential)
              .then((result) => result.user.getIdToken())
              .then((firebaseIdToken) => {
                // Enviar el token al backend
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL_V2}/api/google-exchange-token`,
                    { idToken: firebaseIdToken, login: true }
                  )
                  .then((response) => {
                    localStorage.setItem("userLoggedIn", true);
                    setLoading(false);
                    navigate(`/${i18n.language}/colormatch`);
                  })
                  .catch((error) =>
                    console.error(
                      "Error al enviar el ID Token al backend:",
                      error
                    )
                  );
              })
              .catch((error) => {
                Alert.error(
                  "Error authenticating with Google. Please try again"
                );
              });
          } else {
            // El usuario no existe
            Alert.error("User does not exist. Please sign up first.");
          }
        })
        .catch((error) => {
          console.error("Error checking user existence:", error);
          Alert.error("An error occurred. Please try again later.");
        });
    } else {
      console.log("No se encontró el token de acceso en la redirección.");
    }
  }, [navigate]);

  return (
    <Container fluid={true}>
      <Row className={style.rowContainer}>
        <Col className={style.colContainer}>
          <h1 className={style.title}>{t("Log In")}</h1>
          <Card className={style.card}>
            <Card.Body>
              <NavLinkWithLocale to="/createAccount" className={style.register}>
                {t("Don’t have an account?")}{" "}
                {/* <span className={style.registerSignupTxt}>Subscribe now</span> */}
                <span className={style.registerSignupTxt}>{t("Sign Up")}</span>
              </NavLinkWithLocale>
              <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={style.formLabels}>Email</Form.Label>
                  <Form.Control
                    isInvalid={
                      loginFields.email.length > 0 &&
                      !loginFields.email.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                    }
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={loginFields.email}
                    onChange={handleFormControl}
                    className={style.formInputs}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("You must write a valid email")}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={style.formLabels}>
                    {t("Password")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      /* isInvalid={
                        loginFields.password.length > 0 &&
                        loginFields.password.length < 4
                      } */
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={loginFields.password}
                      onChange={handleFormControl}
                      className={style.formInputs}
                    />
                    {showPassword ? (
                      <Button
                        className={style.showPswBtn}
                        variant="dark"
                        onClick={handleShowPassword}
                      >
                        <GoEye />
                      </Button>
                    ) : (
                      <Button
                        className={style.showPswBtn}
                        variant="dark"
                        onClick={handleShowPassword}
                      >
                        <GoEyeClosed />
                      </Button>
                    )}
                    {/* <Form.Control.Feedback type="invalid">
                      {t("Minimum 6 characters")}
                    </Form.Control.Feedback> */}
                  </InputGroup>
                  {/* <small>Minimum 6 characters</small> */}
                </Form.Group>
                <Button type="submit" className={style.loginBtn}>
                  {t("Log In")}{" "}
                  {loading && (
                    <Spinner
                      as="span"
                      size="sm"
                      animation="grow"
                      role="status"
                    />
                  )}
                </Button>
                <NavLinkWithLocale
                  to="/resetPassword"
                  className={style.forgotPassLink}
                >
                  <span className={style.registerSignupTxt}>
                    {t("Forgot your password?")}
                  </span>
                </NavLinkWithLocale>

                <div className="d-flex align-items-center my-3">
                  <div className="flex-grow-1 border-bottom"></div>
                  <span className="mx-3 text-muted">OR</span>
                  <div className="flex-grow-1 border-bottom"></div>
                </div>

                <Button
                  onClick={handleLoginWithGoogle}
                  className={`mt-3 ${style.signupWithGoogleBtn}`}
                >
                  <FcGoogle size={"1.5rem"} />
                  Log In with Google
                </Button>

                {/* <Button 
                  onClick={handleLoginWithFacebook} 
                  className={`mt-3 ${style.signupWithGoogleBtn}`}
                >
                  <FaFacebook size={'1.5rem'}/>Log In with Facebook
                </Button> */}
              </Form>
            </Card.Body>
          </Card>
          <Toaster richColors position="bottom-center" />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
