import React from "react";
import { RiFolderLine } from "react-icons/ri";
import { FaSignOutAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { IoSettingsOutline } from "react-icons/io5";
import {
  Col,
  Nav,
  NavDropdown,
  Offcanvas,
  Placeholder,
  Row,
} from "react-bootstrap";
import { LanguageSwitcher, NavLinkWithLocale } from "../index";
import style from "./HeaderBar.module.css";

const DesktopMenu = ({ user, loading, handleLogOut, actualLocation }) => {
  const { t, i18n } = useTranslation();

  return (
    <Offcanvas.Body>
      <Nav className={`ms-4 ms-xl-5 ${style.offcanvasNav}`}>
        <Row className={`align-items-center ${style.offcanvasNav}`}>
          <Col md="auto" className="m-0 p-0">
            <Row>
              <Col md="auto">
                <NavLinkWithLocale
                  className={`${style.links} ${
                    actualLocation === `/${i18n.language}/product`
                      ? style.activeLink
                      : ""
                  }`}
                  to="/product"
                >
                  {t("Plans & Pricing")}
                </NavLinkWithLocale>
              </Col>
{/*               {!user && (
                <Col md="auto">
                  <NavLinkWithLocale
                    className={`${style.links} ${
                      actualLocation === `/${i18n.language}/colormatch`
                        ? style.activeLink
                        : ""
                    }`}
                    to="/colormatch"
                  >
                    {t("Create Palette")}
                  </NavLinkWithLocale>
                </Col>
              )}
              <Col md="auto">
                <NavLinkWithLocale
                  className={`${style.links} ${style.differentLink} ${
                    actualLocation === `/${i18n.language}/search`
                      ? style.activeLink
                      : ""
                  }`}
                  to="/search"
                >
                  {t("Search Balloons")}
                </NavLinkWithLocale>
              </Col> */}
            </Row>
          </Col>
          <Col md={loading ? 7 : "auto"} xl={8} className="m-0 p-0">
            <Row className={`p-0 m-0 align-items-center justify-content-end`}>
            {!user && (
                <Col md="auto" className={style.bgIcon}>
                  <NavLinkWithLocale
                    className={`${style.links} ${
                      actualLocation === `/${i18n.language}/colormatch`
                        ? style.activeLink
                        : ""
                    }`}
                    to="/colormatch"
                  >
                    {t("Create Palette")}
                  </NavLinkWithLocale>
                </Col>
              )}
              <Col md="auto" className={style.bgIcon}>
                <NavLinkWithLocale
                  className={`${style.links} ${style.differentLink} ${
                    actualLocation === `/${i18n.language}/search`
                      ? style.activeLink
                      : ""
                  }`}
                  to="/search"
                >
                  {t("Search Balloons")}
                </NavLinkWithLocale>
              </Col>
              {loading ? (
                <>
                  <Col className="p-0 m-0" md={4} xl={3}>
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={10}
                        size="sm"
                        style={{ borderRadius: "10px" }}
                      />
                    </Placeholder>
                  </Col>
                  <Col className="p-0 m-0" md={4} xl={3}>
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={10}
                        size="sm"
                        style={{ borderRadius: "10px" }}
                      />
                    </Placeholder>
                  </Col>
                </>
              ) : user ? (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem} ${style.bgIcon}`} md="auto">
                    <NavLinkWithLocale
                      className={`${style.links} ${
                        actualLocation === `/${i18n.language}/colormatch`
                          ? style.activeLink
                          : ""
                      }`}
                      to="/colormatch"
                    >
                      {t("Workspace")}
                    </NavLinkWithLocale>
                  </Col>
                  {user.role === "1" && (
                    <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                      <NavLinkWithLocale
                        className={`${style.links} ${
                          actualLocation === `/${i18n.language}/dashboard`
                            ? style.activeLink
                            : ""
                        }`}
                        to="/dashboard"
                      >
                        Dashboard
                      </NavLinkWithLocale>
                    </Col>
                  )}
                  <Col className="p-0 m-0" md="auto">
                    <NavDropdown
                      title={user.name}
                      className={style.links}
                      align={{ lg: "end" }}
                    >
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/profile/palettes"
                        className={style.userOptions}
                      >
                        <RiFolderLine />
                        {t("Saved palettes")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/profile"
                        className={style.userOptions}
                      >
                        <IoSettingsOutline />
                        {t("Account settings")}
                      </NavDropdown.Item>
                      <NavDropdown.Divider className={style.dropdownDivider} />
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/terms"
                        className={style.sublinks}
                      >
                        {t("Terms")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/privacyPolicy"
                        className={style.sublinks}
                      >
                        {t("Privacy Policy")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/contactUs"
                        className={style.sublinks}
                      >
                        {t("Contact Us")}
                      </NavDropdown.Item>
                      <NavDropdown.Divider className={style.dropdownDivider} />
                      <NavDropdown.Item
                        onClick={handleLogOut}
                        className={style.userOptions}
                      >
                        <FaSignOutAlt size="1rem" />
                        {t("Log out")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Col>
                </>
              ) : (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLinkWithLocale
                      className={`${style.links} ${style.differentLink} ${
                        actualLocation === `/${i18n.language}/createAccount`
                          ? style.activeLink
                          : ""
                      }`}
                      to="/createAccount"
                    >
                      {t("Sign Up")}
                    </NavLinkWithLocale>
                  </Col>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLinkWithLocale
                      className={`${style.links} ${
                        actualLocation === `/${i18n.language}/login`
                          ? style.activeLink
                          : ""
                      }`}
                      to="/login"
                    >
                      {t("Log In")}
                    </NavLinkWithLocale>
                  </Col>
                </>
              )}
              <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                <LanguageSwitcher drop="down" position="header" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Nav>
    </Offcanvas.Body>
  );
};

export default DesktopMenu;
