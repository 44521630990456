import React from "react";
import { MdDone } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { NavLinkWithLocale } from "../../../components";
import { subscribeToPro } from "../../../utils/auxiliarFunctions";
import style from "./PlanSquare.module.css";

const PlanSquare = ({
  title,
  price,
  subtitle,
  planInfo,
  planBodyTitle,
  advantages,
  button,
}) => {
  const { user } = useAppContext();
  const { t, i18n } = useTranslation();

  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const handleSubscribe = async () => {
    subscribeToPro(false, i18n.language);
  };

  return (
    <Col xs={10} md={5} lg={4} className={style.planSquare}>
      <Row>
        <Col xs={12}>
          <h2 className={style.planTitle}>{title}</h2>
          <section className={style.planSubtitleSec}>
            <p className={style.planPrice}>{price}</p>
            <p style={{ fontSize: "16px" }}>{title === "Pro" && "USD"}</p>
            <span>{subtitle}</span>
          </section>
          <br />
          <p className={style.planInfo}>{planInfo}</p>
          {!user ? (
            title !== "Pro" ? (
              <NavLinkWithLocale to="/createAccount">
                <Button
                  variant="secondary"
                  size="lg"
                  className={style.signUpBtn}
                >
                  {t("GET STARTED FOR FREE")}
                </Button>
              </NavLinkWithLocale>
            ) : (
              <>
                <Button
                  variant="secondary"
                  size="lg"
                  className={style.signUpBtn}
                  onClick={handleSubscribe}
                >
                  {t("SIGN UP FOR PRO")}
                </Button>
                <br />
                <span className={style.cancelTxt}>{t("Cancel at any time.")}</span>
              </>
            )
          ) : (
            !userPro &&
            title === "Pro" && (
              <>
                <Button
                  variant="secondary"
                  size="lg"
                  className={style.signUpBtn}
                  onClick={handleSubscribe}
                >
                  {t("SIGN UP FOR PRO")}
                </Button>
                <br />
                <span className={style.cancelTxt}>Cancel at any time</span>
              </>
            )
          )}
        </Col>
      </Row>
      <hr className={style.hr} />
      <Row>
        <Col className="my-3" xs={12}>
          <h4 className={style.planBodyTitle}>{planBodyTitle}</h4>
        </Col>
        {advantages.map((item, index) => (
          <Col className={`mb-3 align-items-center`} xs={12} key={index}>
            <Row className="align-items-start">
              <Col xs={1} className="me-2">
                <MdDone className={style.icon} />
              </Col>
              <Col xs={10}>
                <h4 className={`m-0 ${style.planBodyText}`}>{item.text}</h4>
              </Col>
              {item.subtitle && (
                <Col className={style.planSubtitle} xs={12}>
                  <p className={style.planBodySubtext}>{item.subtitle}</p>
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default PlanSquare;
