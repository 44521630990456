export const paragraphsEn = [
  {
    text: `Balloon Color Match Terms of Use
      Last updated: June 12, 2024
      These Terms of Use state the terms and conditions under which you can use the Balloon Color Match web application (“Balloon Color Match,” “we,” “us,” or “our”). Please read these Terms of Use carefully. By clicking on the “create account” button, completing the registration process or accessing or using any of the services of the Balloon Color Match application, you acknowledge that (1) you have read, understood, and agree to be legally bound by these Terms of Use (2) you represent and warrant that you are at least 18 years old or older and able to form a binding contract with Balloon color Match (3) The information you provided in connection with your registration for the usage of Balloon Color Match application is accurate and complete and you have the authority to enter into the agreement personally or on behalf of the company you have named, and to bind that company to the agreement (4) To use Balloon Color Match application you must reside outside of specified locations like Cuba, Iran, North Korea, Syria, Russia, the Crimea, Donetsk ("DNR"), and Luhansk ("LNR") regions of Ukraine or any other region under government embargoes; and not be listed in any prohibitory registers maintained by the United States or other relevant jurisdictions governing transactions with specific entities, individuals, and territories. When registering for the usage of Balloon Color Match application, the designation "You" or "User" pertains to the individual or legal entity identified as the User, as relevant in the given context. If you do not agree to any of these terms, then please do not use the Balloon Color Match application. These Terms of Use may be updated by Balloon Color Match application from time to time by posting the updates on our site. Any changes will be effective only after the effective date of the change and will not affect any dispute arising prior to the effective date of the change.

      The Balloon Color Match application is one of the Campogis LLC products, provided to you by Campogis LLC. These Terms of Use therefore constitute an agreement between you and Campogis LLC.`,
  },
  {
    title: `Description of the Balloon Color Match`,
    text: `The Balloon Color Match is a web application that allows registered users to build color palettes with a database of balloon brands, supplies and other decor items. It also provides other utilities, usages and features functionality to read and match balloon colors.`,
  },
  {
    title: `Registration, Account and Password`,
    text: `If you would like to use the Balloon Color Match application, you will need to access it from the site www.ballooncolormatch.com. Upon accessing the Balloon Color Match application, you will be required to create an account. You represent and warrant that all login and authorization information you submit is truthful and accurate and you will maintain the accuracy of such information. It is your responsibility to maintain the confidentiality of your account name and password. You are accountable for any account you can access and all activities carried out within such accounts, regardless of whether you sanctioned those activities. Promptly inform us of any unauthorized access or utilization of your accounts. We do not accept liability for losses incurred due to stolen or compromised passwords. We do not possess your current password, and for security purposes, we can solely guide you on resetting it. Based on the details provided in your account, we may reach out to you.`,
  },
  {
    title: `Single Account Usage`,
    text: `Each subscription plan entitles you to a single user account, which is intended for the use of the individual or entity that registered for the subscription. Sharing your account credentials with others or allowing multiple users to access the usage of Balloon Color Match application through a single account is strictly prohibited. Each user must have their own individual account and subscription plan. Violation of this policy may result in immediate suspension or termination of your account without a refund. Additionally, we reserve the right to take legal action to recover any damages or losses incurred as a result of unauthorized use of a single account by multiple users. By subscribing to a plan, you agree to these terms and acknowledge that any breach may result in the actions outlined above.`,
  },
  {
    title: `Fees, Payments, Cancellation and Taxes`,
    text: `Free Version: Balloon Color Match offers a free version of the application that allows users to access limited features without any subscription fees or payments. The free version does not require any payment and is available for users to explore basic functionalities of the platform at no cost. Balloon Color Match reserves the right to modify the features and limitations of the free version at any time.

      Fees (Paid Version): In consideration for the usage of the paid version of Balloon Color Match, User will pay the subscription fees as described during the checkout process. The paid version includes access to additional features and functionality not available in the free version. Auto-billing applies to the paid version, as described during the check-out process. Balloon Color Match reserves the right to modify the features and limitations of the paid version at any time.

      Payments: For the paid version, Balloon Color Match will charge users the subscription Fees in advance for each billing period on or after the first day of such billing period. All Fees for usage of the paid version of Balloon Color Match are due and payable in US Dollars and are non-refundable. If User is paying by credit card: (a) User hereby irrevocably authorizes Balloon Color Match to charge the credit card or other payment method provided for any such amounts when due, (b) amounts due will be automatically charged, (c) if User’s credit card is declined, Balloon Color Match will attempt to reach out to User for a new payment method, and (d) if User’s credit card expires, User hereby gives Balloon Color Match permission to submit the credit card charge with a later expiration date. If Balloon Color Match fails to resolve an issue with User resulting from a credit card decline or expiration, Balloon Color Match may terminate the account due to non-payment. User agrees to notify Balloon Color Match of all billing disputes within fourteen (14) days of delivery of the billing statement or invoice, and disputes not made within that time are waived. Late payments, including those resulting from credit card declines, will accrue interest at a rate of one and one-half percent (1.5%) per month, or the highest rate allowed by applicable law, whichever is lower.

      Cancellation: For subscription plans, you may cancel at any time by providing a 30-day notice. The cancellation will take effect at the end of your current billing cycle following the 30-day notice period. We will not refund any prepaid amounts for the billing cycle in which the cancellation notice was given. The free version does not require cancellation, as it does not incur any subscription fees.
      
      Taxes: “Tax” or “Taxes” means all applicable taxes, including but not limited to indirect taxes such as goods and services tax (“GST”), value added tax (“VAT”), sales tax, fees, duties, levies, or other similar taxes. Unless otherwise stated, any consideration, amount payable, prices, fees, payment terms, and/or any other amounts are exclusive of Taxes. In the event that any amount payable by you to Balloon Color Match is subject to Taxes, Balloon Color Match shall collect the full amount of those Taxes from you and said collection shall not reduce or somehow impact the amount to which Balloon Color Match is entitled. You will reimburse and indemnify Balloon Color Match for any Taxes, interest, and penalties that Balloon Color Match may be compelled to pay on account of your non-payment. You must pay any applicable Taxes. In the event that any payments and/or amount payable by you to Balloon Color Match is subject to (i) any withholding or similar tax; (ii) any Taxes not collected by Balloon Color Match; or (iii) any other Taxes or other government levy of whatever nature, the full amount of that tax or levy shall be solely your responsibility and shall not reduce the amount to which Balloon Color Match is entitled under the Agreement. You will indemnify and hold Balloon Color Match harmless against any and all claims by any competent tax authority related to any such withholding or similar taxes and any penalties and/or interest thereon.`,
  },
  {
    title: `Use of Personal Data`,
    text: `Your use of the Balloon Color Match application may involve the transmission to Balloon Color Match application of certain personally-identifiable information (“Personal Information”). Balloon Color Match policies with respect to the collection and use of Personal Information are governed according to its Privacy Policy, which is hereby incorporated by reference in its entirety.`,
  },
  {
    title: `Intellectual Property`,
    text: `Balloon Color Match retain all right, title and interest in and to the Balloon Color Match application usage and site, all software and technology incorporated therein, and all content made available to you through the Balloon Color Match application, including, without limitation, patents, trade secrets, trademarks, service marks copyrights and other intellectual property rights used and displayed through the Balloon Color Match application (collectively, the “Balloon Color Match IP”). Except as expressly set forth in these Terms of Use, you have no right in or to the Balloon Color Match IP.`,
  },
  {
    title: `Color Match`,
    text: `We strive to provide the best possible color match options for balloons, florals, confetti, and any product supported by our application through our database library. However, we cannot guarantee an exact match. Proposed colors or matching colors depend on the quality of pictures and are subject to color hex categorization, which may sometimes result in inaccuracies. We ask users to upload images based on specified image file types and sizes. The quality of the image is crucial for the best color matching accuracy. Dark, blurry, or overly bright images may provide false or inaccurate options.
      We do our best to keep our database up to date with manufacturers' product availability, but we do not take any responsibility or liability if manufacturers' products are no longer available or discontinued. While we strive to keep our library current, we cannot guarantee its accuracy. Balloon Color Match does not take any responsibility or liability for the loss of business incurred by users using Balloon Color Match for business and social purposes.`,
  },
  {
    title: `Browser Compatibility and IT-Related Usage`,
    text: `To ensure the best experience with Balloon Color Match, we recommend using our web application on newer operating systems and modern browsers. While our application is designed to be compatible with a wide range of devices, it may not perform optimally on significantly outdated systems. For optimal performance, please ensure your device meets the current standards for web applications. Additionally, we cannot be held responsible or liable for any interruptions in service or performance issues caused by internet connectivity, internet provider security restrictions, modem settings, cyberattacks, device's operating system version incompatibilities, or any other IT-related issues.`,
  },
  {
    title: `Feedback`,
    text: `In the event that User or its Authorized Users provide any comments or suggestions in connection with the usage of Balloon Color Match Application, whether written or oral (collectively, the “Feedback”), Balloon Color Match, in its sole discretion, shall be entitled to use the Feedback without restriction, and such Feedback will not be treated as confidential to User. User hereby grants Balloon Color Match, on behalf of itself and its Authorized Users, a worldwide, non-exclusive, irrevocable, perpetual, royalty-free right and license to incorporate the Feedback into Balloon Color Match products and services.`,
  },
  {
    title: `Advertisements`,
    text: `Balloon Color Match reserves the right to display advertisements at any time at its sole discretion on its site, in User account or during the usage of Balloon Color Match application.`,
  },
  {
    title: `Account Dispute`,
    text: `You are expected not to seek access to or details about an account that does not belong to you, and any disputes related to an account should be addressed directly with the relevant party. We decide who owns an account which is determined by various criteria, which may include the content within the account and the contact and profile information associated with it. In situations where conflicting contact or profile details exist, or if ownership cannot be reasonably ascertained, we will request that you settle the matter through appropriate channels external to Balloon Color Match. Upon identification of a dispute, we reserve the right to suspend any account linked to the conflict, which may involve disabling login and communication capabilities, in order to safeguard the security and confidentiality of the data contained within the account until the dispute is satisfactorily resolved.`,
  },
  {
    title: `Account Deletion`,
    text: `Users have the right to delete their accounts at any time. To delete your account, you can request account deletion by sending an email to support@ballooncolormatch.com from the email address associated with your account. Please include "Account Deletion Request" in the subject line and provide your account details for verification.
      Upon receiving your request, we will process it within 30 days. Deleting your account is a permanent action and cannot be undone. Once your account is deleted, all associated data, including user information, settings, and any saved content, will be permanently removed from our servers.
      Please note: 
      - Some information may be retained for a limited period if required by law or for legitimate business purposes. 
      - If you have any subscriptions, they will be canceled upon account deletion. If you have any questions or need further assistance, please contact our support team at support@ballooncolormatch.com.`,
  },
  {
    title: `Community Guidelines`,
    text: `By accessing and/or using the Balloon Color Match application, you hereby agree that:
      You will not use the Balloon Color Match application for any unlawful purpose; 
      You will not upload, post, e-mail, transmit, or otherwise make available any content that:
      infringes any copyright, trademark, right of publicity, or other proprietary rights of any person or entity; or is threatening, tortious, defamatory, libelous, indecent, obscene, pornographic, sexually explicit, invasive of another’s privacy, or promotes extreme violence or cruelty to animals, or contains hate speech (i.e., speech that attacks or demeans a group based on race or ethnic origin, religion, disability, gender, age, veteran status, and/or sexual orientation/gender identity; or discloses any sensitive information about another person, including that person’s e-mail address, postal address, phone number, credit card information, or any similar information. You will not “stalk” or otherwise harass another; You will not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; and You will not interfere with or attempt to interrupt the proper operation of the Balloon Color Match application through the use of any virus, device, information collection or transmission mechanism, software or routine, or access or attempt to gain access to any data, files, or passwords related to the Balloon Color Match application through hacking, password or data mining, or any other means.`,
  },
  {
    title: `Disclaimer and Limitation of Liability`,
    text: `YOU ACKNOWLEDGE AND AGREE THAT THE BALLOON COLOR MATCH APPLICATION, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, FUNCTIONS AND MATERIALS, IS PROVIDED “AS IS,” “AS AVAILABLE”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NONE OF BALLOON COLOR MATCH, ITS AFFILIATES, SUBSIDIARIES, OR ITS OR THEIR OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY THE “BALLOON COLOR MATCH PARTIES”) WARRANT THAT THE BALLOON COLOR MATCH APPLICATION, CONTENT, FUNCTIONS OR MATERIALS CONTAINED THEREIN WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. NONE OF THE BALLOON COLOR MATCH PARTIES SHALL BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR DEVICE OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO OR USE OF THE BALLOON COLOR MATCH APPLICATION.
      IN NO EVENT SHALL ANY OF THE BALLOON COLOR MATCH PARTIES BE LIABLE FOR SPECIAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, REVENUES OR SAVINGS, EVEN IF ANY BALLOON COLOR MATCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE. IN NO EVENT SHALL THE TOTAL CUMULATIVE LIABILITY OF THE BALLOON COLOR MATCH PARTIES FOR DIRECT DAMAGES ARISING UNDER THESE TERMS OF USE OR RELATING THERETO, EXCEED ONE HUNDRED DOLLARS ($100). BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR NEGLIGENCE, CONSEQUENTIAL, INCIDENTAL OR OTHER DAMAGES, IN SUCH JURISDICTIONS THE LIABILITY OF THE BALLOON COLOR MATCH PARTIES IS LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH BALLOON COLOR MATCH WEB APPLICATION IS TO STOP USING THE BALLOON COLOR MATCH APPLICATION.`,
  },
  {
    title: `External Sites`,
    text: `The Balloon Color Match application will contain links to third-party websites and applications (“External Sites”), but Balloon Color Match does not endorse and is not responsible for the content of any linked External Sites. Please refer to the terms of use and privacy policies of the External Sites for more information.`,
  },
  {
    title: `Indemnification`,
    text: `You agree to defend, indemnify, and hold the Balloon Color Match Parties harmless from and against any claims, actions, or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from: (i) your breach of these Terms of Use; (ii) your access to, use, or misuse of the Balloon Color Match application; (iii) any usage through your account; or (iv) your violation of any third-party right, including without limitation any intellectual property, or privacy right.`,
  },
  {
    title: `Compliance with Balloon Color Match Applicable Laws`,
    text: `The Balloon Color Match is based in the United States. You represent and warrant that your use of Balloon Color Match application will comply with all applicable laws and regulations of United States, including as may be amended or adopted over time. You’re responsible for determining whether the usage of Balloon Color Match application is suitable for you to use in light of your obligations under any regulations. Balloon Color Match makes no claims concerning whether the Balloon Color Match application or Balloon Color Match IP may be appropriate for use outside of the United States. If you access the Balloon Color Match application or the Balloon Color Match IP from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.`,
  },
  {
    title: `Termination`,
    text: `Either you or Balloon Color Match retains the right to end the Agreement at any point and for any cause. You have the option to terminate your Balloon Color Match account, or we may opt to terminate the Agreement by notifying you of our decision. It is within our discretion to suspend the usage of Balloon Color Match application to you at any time, with or without justification. Please be aware that we do not offer refunds or reimbursements under any circumstances. In the event of your account being inactive for 12 months or more, we hold the authority to close your account without refunding any prepaid amounts. Upon termination of your account, you explicitly agree that we reserve the right to permanently erase your account along with all associated data. Usernames are singular and cannot be reused. If your account is terminated, the username becomes unavailable for future accounts and cannot be reclaimed.`,
  },
  {
    title: `Disclaimers`,
    text: `Neither Balloon Color Match nor Balloon Color Match Parties hold responsibility for the actions of third parties, agencies, linked websites, or other Members, including third-party applications, products, or services utilized in conjunction with the Service (referred to as "Third-Party Integrations"). Your engagement with any Third-Party Integration and any associated rights are strictly between you and the relevant third party. We do not oversee the terms, privacy, security, or reliability of any Third-Party Integration, nor the practices and policies implemented by them. We provide no warranties of any nature and bear no liability for your utilization of any Third-Party Integration.`,
  },
  {
    title: `Procedure for Claims Under the Digital Millenium Copyright Act`,
    text: `If you believe the Balloon Color Match application contains any content that infringes your copyright, please contact Balloon Color Match’s Copyright Agent, as detailed below, with the following information:
      An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
      A description of the copyrighted work that you claim has been infringed;
      A description of where the material that you claim is infringing is located on the Balloon Color Match application;
      Your address, telephone number and email address;
      A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and
      A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or duly authorized to act on the copyright owner’s behalf.

      To contact Balloon Color Match’s Copyright Agent by email, please write to support@ballooncolormatch.com, with COPYRIGHT NOTICE in the subject line.`,
  },
  {
    title: `Force Majeure`,
    text: `We shall not be deemed accountable for any delays or inability to perform any aspect of the usage of Balloon Color Match application due to circumstances beyond our control. Such instances encompass, but are not restricted to, acts of nature, alterations in laws or regulations, embargoes, warfare, terrorist activities, civil unrest, fires, seismic events, nuclear incidents, floods, labor strikes, power outages, volcanic eruptions, exceptionally inclement weather conditions, and intrusions by hackers or third-party internet service providers.`,
  },
  {
    title: `Miscellaneous`,
    text: `In the event that any portion of these Terms of Use is held to be invalid or unenforceable, then such portion shall be construed in accordance with the applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of these Terms of Use shall remain in full force and effect. This section, the section entitled Intellectual Property, Indemnification, and the section entitled Disclaimer and Limitation of Liability shall survive the termination of these Terms of Use. You may not assign these Terms of Use. No waiver shall be effective unless in writing. Neither the course of conduct between parties nor trade practice shall act to modify any provision of these Terms of Use. These Terms of Use and any other agreements between the parties entered into through the Balloon Color Match application shall be governed by and construed in accordance with the laws of the State of Illinois. Except for proceedings commenced by Balloon Color Match to protect its intellectual property or confidential information which may be brought in any court of competent jurisdiction, the parties mutually agree that any and all disputes arising hereunder shall be resolved exclusively by state or federal courts located in Cook County, Illinois. These Terms of Use contain the entire agreement of the parties concerning the Balloon Color Match application, and supersede all existing agreements and all other oral, written or other communication between the parties concerning its subject matter.`,
  },
  {
    title: `How to Contact Balloon Color Match`,
    text: `If you have any questions or comments about these Terms of Use or the Balloon Color Match web application, please contact Balloon Color Match by email at support@ballooncolormatch.com.`,
  },
];
