import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Button,
  Carousel,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext, useColorMatchContext } from "../../context";
import { Slider, ZoomCanvas } from "../index";
import {
  Alert,
  drawZoom,
  loadImage,
  useColorMatch,
  useScreenSize,
  useUpdateFirstShowMatchingMobileHelpTooltip,
} from "../../utils";
import matchSvg from "../../assets/match.svg";
import style from "./CanvasWithZoom.module.css";

const CanvasWithZoomV2 = ({
  selectedColor,
  setSelectedColor,
  brightness,
  index,
  onSelect,
}) => {
  const { images, user, setUser } = useAppContext();
  const { addBalloon } = useColorMatchContext();
  const canvasRefs = useRef([]);
  const zoomRef = useRef(null);
  const [showZoom, setShowZoom] = useState(false);
  const [mousePositions, setMousePositions] = useState({ x: 158, y: 187 });
  const [zoomInitialRender, setZoomInitialRender] = useState(false);
  const [selectedDragColor, setSelectedDragColor] = useState(null);
  const [showMatchingMobileTooltip, setShowMatchingMobileTooltip] =
    useState(false);
  const [showSelectedColor, setShowSelectedColor] = useState(false);
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const updateFirstShowMatchingMobileHelpTooltip =
    useUpdateFirstShowMatchingMobileHelpTooltip();

  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const { mutate: fetchBalloon } = useColorMatch();

  const onSuccessChrome = useCallback(
    (data) => {
      //console.log(data.closerColor)
      const isDuplicated = addBalloon(data);
      if (isDuplicated) {
        Alert.error("This balloon color is already on your palette.");
      } else if (!data?.chromeNext?.color) {
        Alert.info(
          "Color linked to both standard and chrome balloon options."
        );
      }
    },
    [addBalloon]
  );

  const onError = useCallback((error) => {
    Alert.error(
      error.message || "Sorry, something went wrong. Please try again"
    );
    console.error("Error:", error);
  }, []);

  const onSuccess = useCallback(
    (data) => {
      // Si data trae chromeNext lo agrega
      if (data?.chromeNext) {
        fetchBalloon(
          {
            elementSelected: data?.chromeNext?.color,
            userPro,
            hsvFilter: true,
          },
          { onError, onSuccess: onSuccessChrome }
        );
      }
      const isDuplicated = addBalloon(data);
      if (isDuplicated) {
        Alert.error("This balloon color is already on your palette.");
      } else {
        screenSize <= 992 && Alert.success("Matched! Scroll to view");
      }
    },
    [fetchBalloon, onSuccessChrome, onError, addBalloon, userPro, screenSize]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        canvasRefs.current[index] &&
        !canvasRefs.current[index].contains(event.target)
      ) {
        //console.log('Clicked outside the canvas');
        // Aquí puedes ejecutar cualquier acción adicional
        setMousePositions({ x: null, y: null });
        // Puedes agregar aquí setShowZoom(false) si es necesario
      }
    },
    [index]
  );

  const handleTouchEnd = () => {
    setMousePositions({ x: 158, y: 187 });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("touchmove", handleClickOutside);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchmove", handleClickOutside);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [mousePositions, handleClickOutside]);

  // Nueva función para obtener la posición inicial dentro del canvas
  const calculateInitialZoomPosition = (canvas) => {
    if (canvas) {
      const width = canvas.width;
      const height = canvas.height;

      return {
        /* x: initialX - rect.left,
        y: initialY - rect.top, */

        x: width / 2,
        y: height / 2,
      };
    }
    return { x: null, y: null };
  };

  // Mostrar zoom al inicio si estamos en pantallas pequeñas
  useEffect(() => {
    if (screenSize <= 992 && images.length > 0 && canvasRefs.current[index]) {
      setTimeout(() => {
        const canvas = canvasRefs.current[index];

        // Calculamos la posición inicial en el centro del canvas
        const initialZoomPosition = calculateInitialZoomPosition(canvas);

        // Solo mostramos el zoom si tenemos una posición válida
        if (initialZoomPosition.x && initialZoomPosition.y) {
          setMousePositions(initialZoomPosition);
          setShowZoom(true);
          setZoomInitialRender(true);

          /* Hacer que el circulo de match tome el primer color del centro del canvas */
          const ctx = canvas.getContext("2d");
          const pixel = ctx.getImageData(
            initialZoomPosition.x,
            initialZoomPosition.y,
            1,
            1
          ).data;

          const rgbToHex = (r, g, b) =>
            `#${r.toString(16).padStart(2, "0")}${g
              .toString(16)
              .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

          const color = rgbToHex(pixel[0], pixel[1], pixel[2]);

          setSelectedDragColor(color);
        }
      }, 500);
    }
  }, [screenSize, images, index]);

  useEffect(() => {
    if (images.length > 0) {
      images.forEach((item, index) => {
        const canvas = canvasRefs.current[index];

        if (canvas) {
          // Primero cargamos la imagen y la dibujamos en el canvas
          loadImage(canvas, item, brightness); /* .then(() => {
            const { width, height, left, top } = canvasRefs.current[index].getBoundingClientRect();
            setMousePositions({x: left + width / 2, y: top + height / 2});
            setShowZoom(true);
          }); */
        }
      });
    }
  }, [images, brightness]);

  useEffect(() => {
    if (showZoom && zoomRef.current) {
      if (mousePositions.x !== null && mousePositions.y !== null) {
        drawZoom(zoomRef.current, canvasRefs.current[index], mousePositions);
      }
    }
  }, [showZoom, mousePositions, index]);

  const handleMove = useCallback(
    (event, index, isTouch = false) => {
      const canvas = canvasRefs.current[index];
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      const rect = canvas.getBoundingClientRect();

      const scaleX = canvas.width / rect.width || 1; // Evitar división por 0
      const scaleY = canvas.height / rect.height || 1;

      const clientX = isTouch ? event.touches[0].clientX : event.clientX;
      const clientY = isTouch ? event.touches[0].clientY : event.clientY;

      const isInsideCanvas =
        clientX >= rect.left &&
        clientX <= rect.right &&
        clientY >= rect.top &&
        clientY <= rect.bottom;

      if (!isInsideCanvas) {
        setShowZoom(false); // Esconder el zoom si está fuera del canvas
        return;
      }

      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY; // Coordenada y visual

      setMousePositions({ x, y });
      setShowZoom(true);

      const fixedRectWidth = 5;
      const fixedRectHeight = 5;

      const canvasX = Math.round(x);
      const canvasY = Math.round(y);

      const startX = Math.max(0, canvasX - (fixedRectWidth / 2) * scaleX);
      const startY = Math.max(0, canvasY - (fixedRectHeight / 2) * scaleY);

      const clampedWidth = Math.min(
        fixedRectWidth * scaleX,
        canvas.width - startX
      );
      const clampedHeight = Math.min(
        fixedRectHeight * scaleY,
        canvas.height - startY
      );

      if (clampedWidth <= 0 || clampedHeight <= 0) {
        console.warn("Clamped width or height is zero.");
        return;
      }

      try {
        // Obtener el área de píxeles dentro del rectángulo
        const imageData = ctx.getImageData(
          startX,
          startY,
          clampedWidth,
          clampedHeight
        ).data;

        // Calcular el color predominante
        const colorCounts = {};
        let maxCount = 0;
        let predominantColor = "#000000";

        for (let i = 0; i < imageData.length; i += 4) {
          const r = imageData[i];
          const g = imageData[i + 1];
          const b = imageData[i + 2];

          const color = `#${r.toString(16).padStart(2, "0")}${g
            .toString(16)
            .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

          colorCounts[color] = (colorCounts[color] || 0) + 1;
          if (colorCounts[color] > maxCount) {
            maxCount = colorCounts[color];
            predominantColor = color;
          }
        }

        // Establecer el color predominante como el color de fondo
        setSelectedDragColor(predominantColor);
        setShowSelectedColor(true);
      } catch (error) {
        console.error("Error getting image data:", error);
      }
    },
    [
      canvasRefs,
      setMousePositions,
      setShowZoom,
      setSelectedDragColor,
      setShowSelectedColor,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    setMousePositions({ x: null, y: null });
    setShowZoom(false);
    setShowSelectedColor(false);
  }, [setMousePositions, setShowZoom]);

  useEffect(() => {
    const addListeners = (canvas, index) => {
      const moveHandler = (event) => handleMove(event, index);
      const leaveHandler = handleMouseLeave; // Usa la referencia de handleMouseLeave

      canvas.addEventListener("mousemove", moveHandler);
      canvas.addEventListener("mouseleave", leaveHandler);

      return { moveHandler, leaveHandler }; // Retornar handlers para la limpieza
    };

    const removeListeners = (canvas, { moveHandler, leaveHandler }) => {
      canvas.removeEventListener("mousemove", moveHandler);
      canvas.removeEventListener("mouseleave", leaveHandler);
    };

    const currentCanvasRefs = canvasRefs.current;

    const listeners = currentCanvasRefs
      .map((canvas, index) => {
        if (canvas) return addListeners(canvas, index);
        return null;
      })
      .filter(Boolean); // Filtrar listeners no nulos

    return () => {
      listeners.forEach((listeners, index) => {
        if (currentCanvasRefs[index]) {
          removeListeners(currentCanvasRefs[index], listeners);
        }
      });
    };
  }, [images, handleMove, handleMouseLeave]);

  const handleCanvasPress = useCallback(
    (event, index, isTouch = false) => {
      if (screenSize <= 992) {
        setShowZoom(false);
        return;
      }
      const canvas = canvasRefs.current[index];
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      const rect = canvas.getBoundingClientRect();

      // Coordenadas del clic en relación al canvas
      const clientX = isTouch ? event.changedTouches[0].clientX : event.clientX;
      const clientY = isTouch ? event.changedTouches[0].clientY : event.clientY;

      const x = clientX - rect.left; // Coordenada x visual
      const y = clientY - rect.top; // Coordenada y visual

      // Dimensiones fijas del rectángulo en píxeles *visuales*
      const fixedRectWidth = 5; // Ancho del rectángulo
      const fixedRectHeight = 5; // Alto del rectángulo

      // Ajustar las coordenadas a la escala interna del canvas
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      const canvasX = Math.round(x * scaleX);
      const canvasY = Math.round(y * scaleY);

      // Coordenadas para dibujar el rectángulo en el canvas interno
      const startX = Math.max(0, canvasX - (fixedRectWidth / 2) * scaleX);
      const startY = Math.max(0, canvasY - (fixedRectHeight / 2) * scaleY);

      const clampedWidth = Math.min(
        fixedRectWidth * scaleX,
        canvas.width - startX
      );
      const clampedHeight = Math.min(
        fixedRectHeight * scaleY,
        canvas.height - startY
      );

      // Obtener el área de píxeles dentro del rectángulo
      const imageData = ctx.getImageData(
        startX,
        startY,
        clampedWidth,
        clampedHeight
      ).data;

      // Calcular el color predominante
      const colorCounts = {};
      let maxCount = 0;
      let predominantColor = "#000000";

      for (let i = 0; i < imageData.length; i += 4) {
        const r = imageData[i];
        const g = imageData[i + 1];
        const b = imageData[i + 2];

        const color = `#${r.toString(16).padStart(2, "0")}${g
          .toString(16)
          .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

        colorCounts[color] = (colorCounts[color] || 0) + 1;
        if (colorCounts[color] > maxCount) {
          maxCount = colorCounts[color];
          predominantColor = color;
        }
      }

      // Usar el color predominante
      if (predominantColor === selectedColor) {
        Alert.error("You have already clicked this color.");
      } else {
        setSelectedColor(predominantColor);

        fetchBalloon(
          { elementSelected: predominantColor, userPro },
          { onSuccess, onError }
        );
      }

      // Dibujar el rectángulo en el canvas con proporciones consistentes
      ctx.strokeStyle = "#FF6563"; // Borde naranja
      ctx.lineWidth = 2 * scaleX; // Ancho del borde

      // Dibujar el rectángulo en coordenadas escaladas
      ctx.strokeRect(startX, startY, clampedWidth, clampedHeight);
    },
    [
      onSuccess,
      onError,
      fetchBalloon,
      userPro,
      selectedColor,
      setSelectedColor,
      screenSize,
    ]
  );

  const handleConfirmMatchColor = async (selectedColor) => {
    fetchBalloon(
      { elementSelected: selectedColor, userPro },
      { onSuccess, onError }
    );
  };

  useEffect(() => {
    if (user) {
      if (user.firstShowMatchingMobileHelpTooltip) {
        setShowMatchingMobileTooltip(true);
      }
    }
  }, [user]);

  const handleCloseMatchingMobileHelpTooltip = () => {
    setShowMatchingMobileTooltip(false);

    setUser((prevState) => ({
      ...prevState,
      firstShowMatchingMobileHelpTooltip: false,
    }));
    updateFirstShowMatchingMobileHelpTooltip.mutate(user.email);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
      className={screenSize >= 992 ? style.canvasDesktopContainer : null}
    >
      {selectedDragColor && screenSize >= 992 && showSelectedColor && (
        <>
          <div className={style.colorCircleContainer}>
            <div
              style={{
                backgroundColor: selectedDragColor && selectedDragColor,
              }}
              className={style.squareDragColor}
              onClick={() => handleConfirmMatchColor(selectedDragColor)}
            ></div>
          </div>
        </>
      )}

      <Slider
        index={index}
        onSelect={onSelect}
        controls={false}
        indicators={false}
        interval={null}
        touch={false}
      >
        {images.map((_, index) => (
          <Carousel.Item key={index}>
            <canvas
              ref={(el) => (canvasRefs.current[index] = el)}
              alt="Canvas"
              className={style.canvas}
              onClick={(event) => handleCanvasPress(event, index)}
              onMouseMove={(event) => handleMove(event, index)}
              onMouseLeave={handleMouseLeave}
              //onTouchStart={(event) => handleCanvasPress(event, index, true)}
              onTouchMove={(event) => handleMove(event, index, true)} // Usar handleMove adaptado para touch
              onTouchEnd={(event) => {
                /* handleCanvasPress(event, index, true); */
                setShowZoom(false);
                handleMouseLeave();
              }}
            />
          </Carousel.Item>
        ))}
      </Slider>

      {selectedDragColor && screenSize <= 992 && (
        <OverlayTrigger
          placement="top"
          show={showMatchingMobileTooltip}
          flip={true}
          overlay={
            <Tooltip className={style.matchingTooltip} id="matching-tooltip">
              <Row className="d-flex align-items-center">
                <Col xs={8}>
                  {/* <p className={style.matchingTooltipTitle}>Find Balloon Match</p> */}
                  <p className={style.matchingTooltipText}>
                    {t(
                      "Drag your finger over image to pick a color, then tap 'Match'."
                    )}
                  </p>
                </Col>
                <Col xs={4}>
                  <Button
                    className={style.matchingTooltipBtn}
                    onClick={handleCloseMatchingMobileHelpTooltip}
                  >
                    Got it
                  </Button>
                </Col>
              </Row>
            </Tooltip>
          }
        >
          <div
            style={{ backgroundColor: selectedDragColor && selectedDragColor }}
            className={style.squareDragColor}
            onClick={() => handleConfirmMatchColor(selectedDragColor)}
          >
            <Image src={matchSvg} width={"85%"} />
          </div>
        </OverlayTrigger>
      )}

      {showZoom &&
        mousePositions.x &&
        mousePositions.y &&
        canvasRefs.current && (
          <ZoomCanvas
            zoomRef={zoomRef}
            ratio={screenSize >= 992 ? "60px" : "90px"}
            border={"whitesmoke"}
            showZoom={showZoom}
            origCanvasRef={canvasRefs.current[index]}
            initialMousePositions={mousePositions}
            zoomInitialRender={zoomInitialRender}
            mousePositions={mousePositions}
          />
        )}
    </div>
  );
};

export default CanvasWithZoomV2;
