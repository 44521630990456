import React from "react";
import { ModalWindow } from "../ModalWindow";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import style from "./UpgradeToProModal.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppContext } from "../../context";
import { subscribeToPro } from "../../utils/auxiliarFunctions";

const UpgradeToProModal = ({ setShowProVersionModal, showProVersionModal }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAppContext();
  const proFeatures = [
    { text: t("Discover alternate matches") },
    { text: t("Use brand preference filters") },
    { text: t("Save and edit your color palettes") },
    { text: t("Share your color palette with clients") },
    { text: t("Share your color palette with co-workers") },
    { text: t("Download your work") },
  ];
  const handleSubscribe = async () => {
    subscribeToPro(user.email, i18n.language);
  };
  return (
    <ModalWindow
      size="lg"
      centered={true}
      helpModal={true}
      show={showProVersionModal}
      onHide={() => setShowProVersionModal(false)}
      closeButton={true}
      style={{ backgroundColor: "#000000" }}
      /* title={"Restart Screen?"} */
      upgradeToProModal={true}
      body={
        <Container className={style.modalUpgradeProContainer}>
          <Row className={style.modalUpgradeProContent}>
            <Col
              xs={{ order: 1, span: 12 }}
              lg={{ order: 0, span: 6 }}
              className={style.modalUpgradeProTextCol}
            >
              <h2 className="mb-4">{t("Go Pro to unlock all features.")}</h2>
              <p className={`${style.planBodyText} mb-4`}>
                {t(
                  "Unlock premium features to run your business more efficiently."
                )}
              </p>
              {proFeatures.map((item, index) => (
                <Col xs={12} key={index}>
                  <Row>
                    <Col xs={12} className={`${style.planBodyTextCol}`}>
                      <Row>
                        <Col xs={1}>
                          <FaStar color="#FF6563" />
                        </Col>
                        <Col xs={10}>
                          <p className={style.planBodyText}>{item.text}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
              <Button
                onClick={handleSubscribe}
                className={`mt-2 ${style.actionBtnUpgrade}`}
              >
                {t("Upgrade for just USD $8/month")}
              </Button>
              <span className={style.cancelTxt}>
                {t("You can cancel at any time.")}
              </span>
            </Col>
            <Col xs={{ order: 0, span: 12 }} lg={{ order: 0, span: 6 }}>
              <Image
                src={require("../../assets/bcm-upgrade-modal.png")}
                width={"100%"}
                className={style.modalUpgradeToProImage}
              />
            </Col>
          </Row>
          <Row className={style.modalUpgradeProFooter}>
            <p className="mt-4">
              {t(
                "“I’m definitely obsessed with this tool and use it regularly”"
              )}{" "}
              — Stacy C.
            </p>
          </Row>
        </Container>
      }
    />
  );
};

export default UpgradeToProModal;
