import React, { useMemo, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Dnd, DraggableRow, Inputs } from "../index";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import style from "./Datagrid.module.css";
import {
  MdOutlineFirstPage,
  MdOutlineLastPage,
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
} from "react-icons/md";
import { verticalListSortingStrategy } from "@dnd-kit/sortable";

const Datagrid = ({
  columns: propColumns,
  data,
  setData,
  usersTable,
  imagesUploadedTable,
  filter,
  isReorderable,
  isLoading,
}) => {
  const [sorting, setSorting] = useState(() => {
    if (usersTable) return [{ id: "signupTimestamp", desc: true }];
    if (imagesUploadedTable) return [{ id: "uploadedAt", desc: true }];
    return [];
  });
  const [filtering, setFiltering] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(() => propColumns, [propColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
      sorting,
      globalFilter: filtering,
    },
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = data.findIndex((item) => item.id === active.id);
    const newIndex = data.findIndex((item) => item.id === over.id);

    if (oldIndex === newIndex) return;
    const updatedData = [...data];
    const [movedItem] = updatedData.splice(oldIndex, 1);
    updatedData.splice(newIndex, 0, movedItem);

    setData(updatedData);
  };

  return (
    <Container className={style.container}>
      <Row className={style.datagrid}>
        <Col className="p-0 m-0">
          <Dnd
            sortableItems={data.map((row) => row.id)}
            handleDragEnd={handleDragEnd}
            strategy={verticalListSortingStrategy}
          >
            <table className={style.table}>
              <thead>
                {filter && (
                  <tr>
                    <th colSpan={7}>
                      <InputGroup className="m-3">
                        <Inputs.FormControl
                          value={filtering}
                          placeholder="Filter..."
                          onChange={(e) => setFiltering(e.target.value)}
                        />
                      </InputGroup>
                    </th>
                  </tr>
                )}
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={style.tr}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className={style.headers}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {
                          { asc: <IoIosArrowUp />, desc: <IoIosArrowDown /> }[
                            header.column.getIsSorted() ?? null
                          ]
                        }
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return isReorderable ? (
                    <DraggableRow key={row.original.id} rowId={row.original.id}>
                      {(listeners, isDragging) =>
                        row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={style.cells}
                            {...(cell.column.id === "reorder" ? listeners : {})}
                          >
                            {flexRender(cell.column.columnDef.cell, {
                              ...cell.getContext(),
                              isDragging,
                            })}
                          </td>
                        ))
                      }
                    </DraggableRow>
                  ) : (
                    <tr key={row.id} id={row.original.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={style.cells}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={7}>
                    <Container className="my-2 px-4">
                      <Row className="justify-content-end align-items-center">
                        <Col
                          sm="auto"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className={style.pageSizeText}>
                            Rows per page:
                          </span>
                          <Form.Select
                            size="sm"
                            value={pagination.pageSize}
                            className={style.pageSize}
                            onChange={(e) =>
                              setPagination({
                                pageIndex: 0,
                                pageSize: Number(e.target.value),
                              })
                            }
                          >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                          </Form.Select>
                        </Col>
                        <Col sm="auto">
                          <span className={style.rowsOfTotal}>
                            {pagination.pageIndex * pagination.pageSize + 1}-
                            {Math.min(
                              (pagination.pageIndex + 1) * pagination.pageSize,
                              table.getRowCount()
                            )}{" "}
                            of {table.getRowCount() || 1}
                          </span>
                        </Col>
                        <Col sm="auto">
                          <Button
                            className={style.paginationBtn}
                            size="sm"
                            onClick={() => table.firstPage()}
                            disabled={!table.getCanPreviousPage()}
                          >
                            <MdOutlineFirstPage size="1.5rem" />
                          </Button>
                          <Button
                            className={style.paginationBtn}
                            size="sm"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                          >
                            <MdOutlineNavigateBefore size="1.5rem" />
                          </Button>
                          <Button
                            className={style.paginationBtn}
                            size="sm"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                          >
                            <MdOutlineNavigateNext size="1.5rem" />
                          </Button>
                          <Button
                            className={style.paginationBtn}
                            size="sm"
                            onClick={() => table.lastPage()}
                            disabled={!table.getCanNextPage()}
                          >
                            <MdOutlineLastPage size="1.5rem" />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Dnd>
        </Col>
      </Row>
    </Container>
  );
};

export default Datagrid;

// <DataTable
//   defaultSortFieldId={
//     usersTable
//       ? "signupTimestamp"
//       : imagesUploadedTable
//       ? "uploadedAt"
//       : null
//   }
//   defaultSortAsc={usersTable || imagesUploadedTable ? false : true}
//   fixedHeader
//   fixedHeaderScrollHeight="100%"
//   title={title}
//   columns={columns}
//   data={!imagesUploadedTable ? filteredItems : data}
//   highlightOnHover
//   pointerOnHover
//   responsive
//   pagination
//   paginationResetDefaultPage={resetPaginationToggle}
//   {...(filter && {
//     subHeader: true,
//     subHeaderComponent: !imagesUploadedTable && (
//       <FilterComponent
//         filterText={filterText}
//         onFilter={handleFilter}
//         onClear={handleClear}
//       />
//     ),
//   })}
//   progressPending={isLoading}
// />
