import React from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../utils";
import { useAppContext } from "../../../context";
import style from "../CardsContainer.module.css";

const ViewDetailsRow = ({
  item,
  selected,
  addColor,
  replaceColors,
  buttons,
  closer,
  autocomplete,
  // shared,
}) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { user } = useAppContext();
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const formatSize = (size) => {
    const formattedSize = size.replace(/size/g, ""); // Elimina 'size'

    if (formattedSize.startsWith("h")) {
      // Si tiene 'h', extraemos el número después de 'h'
      return {
        baseSize: null, // Se pone null para asegurar que va al final
        suffix: null,
        isH: true, // Marca que es tipo 'h'
        hValue: parseInt(formattedSize.slice(1), 10), // Extrae el número después de 'h'
        original: formattedSize,
      };
    }

    const [baseSize, suffix] = formattedSize.split("_"); // Divide en base y sufijo
    return {
      baseSize: parseInt(baseSize, 10), // Convierte baseSize a número
      suffix: suffix ? parseInt(suffix, 10) : null, // Convierte sufijo a número si existe
      isH: false, // No es tipo 'h'
      hValue: null, // No aplica
      original: formattedSize, // Mantén el formato original para reconstruir
    };
  };

  const itemSizes =
    item && Object.keys(item).filter((prop) => prop.includes("size"));

  const sortedSizes = itemSizes
    ?.map(formatSize)
    .sort((a, b) => {
      if (a.isH && b.isH) {
        return a.hValue - b.hValue; // Si ambos son tipo 'h', ordenamos por número
      }
      if (a.isH) return 1; // Los 'h' van al final
      if (b.isH) return -1;

      if (a.suffix === null && b.suffix === null) {
        return a.baseSize - b.baseSize; // Ordena por baseSize si no tienen sufijo
      } else if (a.suffix === null) {
        return -1; // a sin sufijo va antes que b con sufijo
      } else if (b.suffix === null) {
        return 1; // b sin sufijo va antes que a con sufijo
      } else {
        return a.baseSize - b.baseSize || a.suffix - b.suffix; // Ordena por baseSize y luego por sufijo
      }
    })
    .map(({ original }) => original); // Recupera el formato original

  return (
    <Row
      className={`${style.cardRow} ${
        autocomplete && style.autocompleteCardRow
      } ${!userPro && style.bluredRow}`}
    >
      <Col xs={3} lg={1} className="m-0 p-0">
        <Image
          src={
            item.imageURL || item?.image?.imageURL || item?.image[0]?.imageURL
          }
          style={{ aspectRatio: "4/5" }}
          width="100%"
          alt="balloonImg"
        />
        <p className={style.displayName}>
          {item?.displayName?.startsWith("http") ? (
            <Image
              src={`${item?.displayName}`}
              width={"100%"}
              alt="displayName"
            />
          ) : (
            item?.displayName || "-"
          )}
        </p>
      </Col>
      <Col xs={9} lg={11}>
        <Row className={`justify-content-around`}>
          {/* <Col xs={5} md={4} lg={1}>
            <small>{item?.color}</small>
            <div style={{backgroundColor: item?.color, width: '50px', height: '20px'}}></div>
            </Col> */}
          <Col
            xs={12}
            md={4}
            lg={3}
            // className={`${screenSize >= 992 && "ms-1"}`}
          >
            <h3 className={style.tableTitle}>{t("BRAND & COLOR NAME")}</h3>
            {item?.combined ? (
              <>
                <h5 className={style.secondaryTitle}>{t("OUTSIDE/INSIDE")}</h5>
                {item?.combination ? (
                  <Image
                    width={"100%"}
                    src={item?.combination}
                    alt="outsideBrand"
                  />
                ) : (
                  <p className={style.dataBrandColorName}>
                    {item?.brand.split("/")[0]} - {item?.outsideColor} /{" "}
                    {item?.brand.split("/")[1]} - {item?.insideColor}
                  </p>
                )}
              </>
            ) : item?.brandName ? (
              <Image src={item?.brandName} width={"70%"} />
            ) : (
              <p className={style.dataBrandColorName}>
                {item?.brand} - {item?.name}
              </p>
            )}
          </Col>
          <Col xs={12} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("AVAILABLE SIZES")}</h3>
            <Row style={{ paddingLeft: ".3rem" }}>
              {sortedSizes?.map((size) => {
                const underscore = size.split("_")[0];

                if (item[`size${size}`]) {
                  if (selected || (!selected && userPro) || autocomplete) {
                    return (
                      <Col
                        key={size}
                        xs="auto"
                        className={`${
                          screenSize <= 992
                            ? style.sizesMobile
                            : style.sizesDesktop
                        }`}
                      >
                        <h3 className={style.dataSizes}>
                          {size.includes("_")
                            ? `${underscore}" LINK`
                            : size > 100 || size.includes("h")
                            ? `${size}`
                            : `${size}"`}
                        </h3>
                      </Col>
                    );
                  } else if (!userPro) {
                    return (
                      <Col key={size} xs="auto">
                        {typeof item[`size${size}`] === "boolean" ? (
                          <Col
                            key={size}
                            xs="auto"
                            className={`${
                              screenSize <= 992
                                ? style.sizesMobile
                                : style.sizesDesktop
                            }`}
                          >
                            <h3 className={style.dataSizes}>
                              {size.includes("_")
                                ? `${underscore}" LINK`
                                : size > 100 || size.includes("h")
                                ? `${size}`
                                : `${size}"`}
                            </h3>
                          </Col>
                        ) : (
                          <Image src={item[`size${size}`]} alt="bluredSize" />
                        )}
                      </Col>
                    );
                  }
                }

                return null;
              })}
            </Row>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <h3 className={style.tableTitle}>{t("FINISH CATEGORY")}</h3>
            <Row>
              <p className={style.dataBrandColorName}>{item?.genericFinish}</p>
            </Row>
          </Col>
          <Col xs={12} lg={2}>
            {(!selected && buttons) || (selected && !closer && buttons) ? (
              <>
              <Row>
                <Col xs={6} md={12}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        className={style.infoTooltip}
                        style={{ zIndex: "200000" }}
                      >
                        {t("Replace with this balloon")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => (!userPro ? null : replaceColors(item))}
                      disabled={!userPro}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? { width: "130px" } : {}}
                    >
                      {t("Replace")}
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col xs={6} md={12}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        className={style.infoTooltip}
                        style={{ zIndex: "200000" }}
                      >
                        {t("Add this balloon to palette")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => (!userPro ? null : addColor(item))}
                      disabled={!userPro}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? { width: "130px" } : {}}
                    >
                      {t("Add to Palette")}
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewDetailsRow;
