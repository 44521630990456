import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext, useColorMatchContext } from "../../context";
import {
  ColorCard,
  ColorCardSkeleton,
  Dnd,
  ModalWindow,
  ViewDetailsRow,
} from "../index";
import {
  Alert as SoonerAlert,
  subscribeToPro,
  useColorMatch,
  useScreenSize,
} from "../../utils";
import style from "./CardsContainer.module.css";

const CardsContainerWithRangesOld = ({ sortable, setSelectedColor }) => {
  const { paletteId } = useParams();
  const { t, i18n } = useTranslation();
  const { user, loadingColorMatch } = useAppContext();
  const {
    colormatch,
    addBalloon,
    requestQueueCount,
    replaceAtIndex,
    isProcessing,
  } = useColorMatchContext();
  const screenSize = useScreenSize();
  const [modal, setModal] = useState({
    show: false,
    color: {},
  });
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const { mutate: fetchBalloon } = useColorMatch();

  const {
    closerColor,
    primaryMatchRange,
    secondMatchRange,
    thirdMatchRange,
    fourthMatchRange,
    fifthMatchRange,
  } = modal.color;

  const matchRanges = [
    { data: primaryMatchRange },
    { data: secondMatchRange },
    { data: thirdMatchRange },
    { data: fourthMatchRange },
    { data: fifthMatchRange },
  ];

  let item = closerColor ? closerColor : modal.color;
  let savedPalette = paletteId ? true : false;

  const hideModal = useCallback(() => {
    setModal((prevModal) => ({ ...prevModal, show: false }));
  }, []);

  const addColor = useCallback(
    (balloon) => {
      fetchBalloon(
        { elementSelected: balloon.color, userPro },
        {
          onSuccess: (data) => {
            const isDuplicate = addBalloon(data);
            if (isDuplicate) {
              SoonerAlert.error(
                "This balloon color is already on your palette."
              );
              return;
            } else {
              SoonerAlert.success("Balloon added!");
              hideModal();
            }
          },
          onError: (error) => {
            SoonerAlert.error("Sorry, something went wrong. Please try again");
            console.error("Error:", error);
          },
        }
      );
    },
    [addBalloon, fetchBalloon, userPro, hideModal]
  );

  const replaceColors = useCallback(
    (balloon) => {
      const index = colormatch.findIndex(
        (item) => item.id === closerColor.color
      );

      fetchBalloon(
        {
          elementSelected: balloon.color,
          userPro,
        },
        {
          onSuccess: (data) => {
            const isDuplicate = addBalloon(data, index);
            if (isDuplicate) {
              SoonerAlert.error(
                "This balloon color is already on your palette."
              );
            } else {
              SoonerAlert.success("Balloon replaced!");
              hideModal();
            }
          },
          onError: (error) => {
            console.error("Error:", error);
          },
        }
      );
    },
    [
      colormatch,
      fetchBalloon,
      closerColor?.color,
      addBalloon,
      userPro,
      hideModal,
    ]
  );

  const handleSubscribe = async () => {
    subscribeToPro(user.email, i18n.language);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const fromIndex = colormatch.findIndex((item) => item.id === active.id);
    const toIndex = colormatch.findIndex((item) => item.id === over.id);

    replaceAtIndex(fromIndex, toIndex);
  };

  // seteo el ultimo elemento del array como login para mostrar el spinner
  const itemsToRender = loadingColorMatch
    ? [...colormatch, "loading"]
    : colormatch;

  const renderCloserColors = () => {
    return (
      <>
        {/* <h4 className={style.listColorTitle}>Selected:</h4> */}
        {screenSize < 992 && (
          <h4 className={`mb-4 ${style.listColorTitle}`}>{t("Selected")}:</h4>
        )}
        <ViewDetailsRow
          selected
          key={item?.color}
          item={item}
          // addColor={addColor}
          // replaceColors={replaceColors}
          buttons={true}
          closer={true}
        />
        <div className="mt-4 ps-3">
          <h4 className={style.listColorTitle}>{t("Alternative options")}:</h4>
        </div>
      </>
    );
  };

  return (
    <>
      <Row
        id="cardsContainer"
        xs={3}
        md={3}
        lg={4}
        xl={4}
        className={`row-gap-4 ${screenSize <= 768 && "mx-sm-4"}`}
      >
        {sortable ? (
          <Dnd
            handleDragEnd={handleDragEnd}
            sortableItems={colormatch}
            strategy={horizontalListSortingStrategy}
          >
            {colormatch.map((color, index) => (
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
              />
            ))}
          </Dnd>
        ) : (
          itemsToRender.map((color, index) =>
            color === "loading" ? (
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="grow" key={index} />
              </div>
            ) : (
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
                setSelectedColor={setSelectedColor}
                savedPalette={savedPalette}
              />
            )
          )
        )}
        {isProcessing && <ColorCardSkeleton key="processing" />}
        {Array.from({ length: requestQueueCount }).map((_, index) => (
          <ColorCardSkeleton key={index} />
        ))}
      </Row>
      <ModalWindow
        show={modal.show}
        onHide={hideModal}
        closeButton={true}
        centered={true}
        size="xl"
        fullscreen={screenSize < 992}
        title={`${t("Selected")}:`}
        viewDetailsModal={true}
        id="view-more-modal"
        body={
          <Row>
            <Col className={`p-0 ${style.infoContainer}`}>
              {renderCloserColors()}

              {matchRanges.every(({ data }) => !data || data.length === 0) ? (
                <p className="mt-4 ps-3">
                  No alternative balloons found at this moment
                </p>
              ) : (
                matchRanges.map(({ data }) =>
                  data?.map((item) => (
                    <ViewDetailsRow
                      key={item.id}
                      item={item}
                      addColor={addColor}
                      replaceColors={replaceColors}
                      buttons={true}
                      savedPalette={savedPalette}
                    />
                  ))
                )
              )}
            </Col>
            <Alert show={!userPro} className={style.alert}>
              <Row className="align-items-center justify-content-around">
                <Col>{t("Go Pro to unlock alternative options.")}</Col>
                <Col xs={4}>
                  <Button
                    onClick={handleSubscribe}
                    className={style.actionBtnUpgrade}
                  >
                    {t("Upgrade")}
                  </Button>
                </Col>
              </Row>
            </Alert>
          </Row>
        }
      />
      {screenSize <= 992 && (
        <Row>
          <Col className="justify-content-end d-flex p-4">
            <p className={style.versionTxt}>
              Beta version 1.1.10. {t("versionTxt")}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardsContainerWithRangesOld;
