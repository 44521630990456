import React, { Fragment, useCallback, useState } from "react";
import {
  Alert as ReactBootstrapAlert,
  Button,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { ModalWindow, ViewDetailsRow } from "../../index";
import {
  Alert,
  subscribeToPro,
  useColorMatch,
  useScreenSize,
} from "../../../utils";
import style from "./SearchColorCard.module.css";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context";

const SearchColorCard = ({ balloon }) => {
  const { t, i18n } = useTranslation();
  const screenSize = useScreenSize();
  const { user } = useAppContext();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showSecondariesModal, setShowSecondariesModal] = useState(false);
  const [matchRanges, setMatchRanges] = useState({});

  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const { mutate: fetchBalloon } = useColorMatch();
  const onSuccess = useCallback((data) => {
    const {
      primaryMatchRange,
      secondMatchRange,
      thirdMatchRange,
      fourthMatchRange,
      fifthMatchRange,
    } = data;

    setMatchRanges({
      primaryMatchRange,
      secondMatchRange,
      thirdMatchRange,
      fourthMatchRange,
      fifthMatchRange,
    });
  }, []);

  const onError = useCallback((error) => {
    Alert.error(
      error.message || "Sorry, something went wrong. Please try again"
    );
    console.error("Error:", error);
  }, []);

  const closerBalloonsByRanges = () => {
    fetchBalloon(
      { elementSelected: balloon.color, userPro },
      { onSuccess, onError }
    );
    setShowSecondariesModal(true);
  };

  const handleSubscribe = async () => {
    subscribeToPro(user.email, i18n.language);
  };

  const rangesArray = [
    { key: "primaryMatchRange" },
    { key: "secondMatchRange" },
    { key: "thirdMatchRange" },
    { key: "fourthMatchRange" },
    { key: "fifthMatchRange" },
  ];

  const onHideSecondaryMatches = () => {
    setShowSecondariesModal(false);
    setMatchRanges({});
  };

  return (
    <Card className={style.card}>
      <Card.Img
        src={balloon.image ? balloon.image[0].imageURL : balloon.imageURL}
        alt={`balloon-${balloon.name}`}
        className={style.cardImg}
      />
      <Card.Body className="px-0 py-1">
        <Card.Title className={`m-0 ${style.cardTitle}`}>
          {balloon.name}
        </Card.Title>
        <Card.Text className={`mb-0 ${style.cardText}`}>
          {balloon.brand}
        </Card.Text>
        <Button
          variant="light"
          className={style.cardBtn}
          onClick={() => setShowDetailModal(true)}
        >
          {t("VIEW INFO")}
        </Button>
        <Button
          variant="light"
          className={style.cardBtn}
          onClick={closerBalloonsByRanges}
        >
          {t("FIND ALTERNATES")}
        </Button>
      </Card.Body>
      {
        <ModalWindow
          show={showDetailModal}
          onHide={() => setShowDetailModal(false)}
          closeButton={true}
          centered={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          title={`${t("Details")}:`}
          viewDetailsModal={true}
          id="view-detail-modal"
          body={<ViewDetailsRow item={balloon} autocomplete />}
        />
      }
      {
        <ModalWindow
          show={showSecondariesModal}
          onHide={onHideSecondaryMatches}
          closeButton={true}
          centered={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          title={`${t("Selected")}:`}
          viewDetailsModal={true}
          id="view-more-modal"
          body={
            <Row>
              <Col className={`p-0 ${style.infoContainer}`}>
                <ViewDetailsRow item={balloon} autocomplete />
                <div className="mt-4 ps-3">
                  <h4 className={style.listColorTitle}>
                    {t("Alternative options")}:
                  </h4>
                </div>
                {rangesArray.every(
                  ({ key }) => matchRanges[key]?.length === 0
                ) ? (
                  <p className="mt-4 ps-3">
                    No alternative balloons found at this moment
                  </p>
                ) : (
                  rangesArray.map(
                    ({ key }) =>
                      matchRanges[key]?.length > 0 &&
                      matchRanges[key].map((item, index) => (
                        <ViewDetailsRow key={index} item={item} index={index} />
                      ))
                  )
                )}
              </Col>
              <ReactBootstrapAlert show={!userPro} className={style.alert}>
                <Row className="align-items-center justify-content-around">
                  <Col>{t("Go Pro to unlock alternative options.")}</Col>
                  <Col xs={4}>
                    <Button
                      onClick={handleSubscribe}
                      className={style.actionBtnUpgrade}
                    >
                      {t("Upgrade")}
                    </Button>
                  </Col>
                </Row>
              </ReactBootstrapAlert>
            </Row>
          }
        />
      }
    </Card>
  );
};

export default SearchColorCard;
