import axios from "axios";
// import { Alert } from "./index";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
// import debounce from "lodash/debounce";
const db = getFirestore(app);

/* export const colorMatchFn = async (
  // colormatch,
  // setColormatch,
  elementSelected,
  userPro
  // options = {}
) => {
  // const { insert, search } = options;
  try {
    if (elementSelected) {
      const response = await axios.get(
        `${process.env.REACT_APP_COLORMATCHFN_URL_V2}`,
        //`${process.env.REACT_APP_COLORMATCHFN_DEV_URL}`,
        {
          params: {
            elementSelected,
            // model2: true
            matchWithNearest: true,
            userPro: userPro,
            hsvFilter: hsvFilter ? true : null
          },
        }
      );

      return response.data;
      
      // if (search) {
      // }
      
      // const {
      //   closerColor,
      //   primaryMatchRange,
      //   secondMatchRange,
      //   thirdMatchRange,
      //   fourthMatchRange,
      //   fifthMatchRange,
      // } = response.data;

      // const ballonWithClosers = {
      //   closerColor,
      //   primaryMatchRange,
      //   secondMatchRange,
      //   thirdMatchRange,
      //   fourthMatchRange,
      //   fifthMatchRange,
      // };

      // if (closerColor) {
      //   if (colormatch.some((item) => closerColor.color === item.color)) {
      //     Alert.error("There is already a balloon with this color.");
      //     return false;
      //   }
      // }
      // console.log(insert);

      // if (insert || insert === 0) {
      //   const copyColorMatch = [...colormatch];
      //   copyColorMatch.splice(insert, 1, ballonWithClosers);
      //   return setColormatch(copyColorMatch);
      // }

      // setColormatch((prevState) => [...prevState, ballonWithClosers]);
    }
  } catch (error) {
    console.error("Error fetching color match:", error);
  }
}; */

export const loadImage = (canvas, image, brightness) => {
  return new Promise((resolve, reject) => {
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      // Dibujar imagen y aplicar brillo
      ctx.drawImage(img, 0, 0, img.width, img.height);
      applyBrightness(ctx, brightness);

      // Resolver la promesa una vez que la imagen esté cargada
      resolve();
    };

    img.onerror = reject; // Si hay error en la carga de imagen, rechazar la promesa

    if (image) {
      img.src = URL.createObjectURL(image); // Cargar la imagen
    } else {
      reject("No image provided"); // Rechazar si no hay imagen
    }
  });
};

const applyBrightness = (ctx, brightness) => {
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  const data = imageData.data;
  for (let i = 0; i < data.length; i += 4) {
    data[i] = Math.min(255, data[i] * brightness); // Red
    data[i + 1] = Math.min(255, data[i + 1] * brightness); // Green
    data[i + 2] = Math.min(255, data[i + 2] * brightness); // Blue
  }
  ctx.putImageData(imageData, 0, 0);
};

export const drawZoom = (zoomCanvas, originalCanvas, position) => {
  if (!zoomCanvas || !originalCanvas) {
    console.error("zoomCanvas or originalCanvas is null");
    console.log("Zoom canvas: ", zoomCanvas);
    console.log("Orig canvas: ", originalCanvas);
    return; // Salir si el canvas es null
  }
  const zoomCtx = zoomCanvas.getContext("2d");

  // Limpiar el canvas de zoom antes de dibujar
  zoomCtx.clearRect(0, 0, zoomCanvas.width, zoomCanvas.height);

  const zoomFactor = 35; // Define el nivel de zoom

  // Ajuste para centrar el zoom en la posición del cursor
  const zoomX = position.x - zoomCanvas.width / (2 * zoomFactor); // Restar la mitad del área visible
  const zoomY = position.y - zoomCanvas.height / (2 * zoomFactor); // Restar la mitad del área visible

  // Dibujar el área ampliada en el canvas de zoom
  zoomCtx.drawImage(
    originalCanvas,
    zoomX,
    zoomY,
    zoomCanvas.width / zoomFactor,
    zoomCanvas.height / zoomFactor,
    0,
    0,
    zoomCanvas.width,
    zoomCanvas.height
  );
};

/* const sortBalloons = (balloons) => {
  //Ordena los globos por categoria
  const categorized = balloons.reduce((acc, item) => {
    if (item.colorCategories) {
      item.colorCategories.forEach((color) => {
        if (!acc[color]) {
          acc[color] = [];
        }
        acc[color].push(item);
      });
    }
    return acc;
  }, {});
  Object.keys(categorized).forEach((category) => {
    categorized[category].forEach();
  });
}; */

const uploadingImage = ({
  image,
  updateTotalProgress,
  collection,
  id,
  name,
}) => {
  const storage = getStorage(app);
  const storageRef = ref(storage, `${collection}/${id}_${name}`);

  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const bytesTransferred = snapshot.bytesTransferred;
        const totalBytesOfCurrentImage = snapshot.totalBytes;
        const imageProgress =
          (bytesTransferred / totalBytesOfCurrentImage) * 100;
        updateTotalProgress(imageProgress, totalBytesOfCurrentImage);
      },
      (error) => {
        console.error("Error al subir la imagen:", error);
        reject(error);
      },
      async () => {
        console.log("Imagen subida correctamente!");
        try {
          const downloadURL = await getDownloadURL(storageRef);
          resolve({ downloadURL, path: storageRef.fullPath });
        } catch (error) {
          console.error("Error obteniendo la URL de descarga:", error);
          reject(error);
        }
      }
    );
  });
};

export const imagesMapToUpload = async ({
  images,
  setProgress,
  collection,
  id,
}) => {
  const totalBytes = images.reduce((acc, image) => acc + image.size, 0); // Tamaño total de las imágenes
  let totalBytesTransferred = 0;

  const updateTotalProgress = (bytesTransferredForImage) => {
    totalBytesTransferred += bytesTransferredForImage;

    const totalProgress = (totalBytesTransferred * 100) / totalBytes;

    setProgress(Math.floor(totalProgress));
  };

  return await Promise.all(
    images.map(async (image) => {
      const { downloadURL, path } = await uploadingImage({
        image,
        updateTotalProgress,
        collection,
        id,
        name: image.name,
      });

      return {
        imageURL: downloadURL,
        imageName: image.name,
        imagePath: path,
      };
    })
  );
};

export const subscribeToPro = async (email, lang) => {
  axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL_V2}/api/stripe/create-checkout-session`,
      {
        lookup_key: "bcm_pricing",
        email: email ? email : false,
        lang: lang,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      window.location.href = response.data.url;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const checkUserExistsInFirestore = async (email) => {
  const usersRef = collection(db, "usuarios");
  const q = query(usersRef, where("email", "==", email));

  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Retorna true si se encontró un usuario
};
