import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import style from "./Commands.module.css";
import { UpgradeToProModal } from "../UpgradeToProModal";

const ActionCommandsHexaTool = () => {
  const { images, setCreateNewPaletteModal, hexaColormatch } =useAppContext();
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState({
    save: false,
    download: false,
    share: false,
  });
  const [showProVersionModal, setShowProVersionModal] = useState(false);

  const handleCreateNewPalette = () => {
    setCreateNewPaletteModal(true);
  };

  return (
    <>
      <Col
        lg={{ order: 0, span: 12 }}
        className={`${style.actionCommandsContainer}`}
      >
        <Row
          className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}
        >
          <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
            <Button
              variant="secondary"
              onClick={handleCreateNewPalette}
              size="sm"
              className={style.actionBtnCNP}
              disabled={hexaColormatch.length === 0 && images.length === 0}
            >
              <FiPlusCircle />
              {t("Create New Palette")}
            </Button>
          </Col>
        </Row>
        {(overlay.share || overlay.save || overlay.download) && (
          <div className={style.overlay} />
        )}
      </Col>

      <UpgradeToProModal setShowProVersionModal={setShowProVersionModal} showProVersionModal={showProVersionModal} />
    </>
  );
};

export default ActionCommandsHexaTool;