import { useEffect } from "react";
import { useAppContext } from "../context";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { AdminRoutes, AuthRoutes, MainRoutes, ProtectedRoutes } from "./index";
import {
  Accessories,
  Balloons,
  ContactUs,
  Dashboard,
  Home,
  Login,
  MyPalettes,
  PasswordReset,
  PaymentSuccess,
  Pricing,
  PrivacyPolicy,
  Profile,
  SearchTool,
  Settings,
  SharedPalette,
  SignUp,
  Terms,
  Tool,
  HexaTool,
  UploadedImages,
  Users,
  HexDistanceCalculator,
  FeaturedBalloons,
} from "../Pages";
import { app } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { ScrollToTop } from "../components/index";
import { useTranslation } from "react-i18next";

const auth = getAuth(app);
const db = getFirestore(app);
const dbRef = collection(db, "usuarios");

export const AppRoutes = () => {
  const { setUser, setLoading } = useAppContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        try {
          const q = query(dbRef, where("email", "==", user.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setUser(doc.data());
              localStorage.setItem("userRole", doc.data().role);
              localStorage.setItem(
                "subscriptionStatus",
                doc.data().stripeSubscriptionStatus
              );
            });
            localStorage.setItem("userLoggedIn", "true");
          } else {
            console.log("User not found in Firestore");
            clearUserData();
          }
        } catch (error) {
          console.log(error);
          clearUserData();
        }
      } else {
        clearUserData();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setUser, setLoading]);

  const clearUserData = () => {
    setUser(null);
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userRole");
    localStorage.removeItem("subscriptionStatus");
  };

  const LanguageSwitcher = () => {
    const { lang } = useParams();
    /* console.log(lang) */

    useEffect(() => {
      if (lang && i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    }, [lang, i18n.language, i18n]);
    return null;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
        {/* Ruta para manejar el cambio de idioma */}
        <Route path="/:lang/*" element={<LanguageSwitcher />} />

        <Route path="/:lang/*" element={<MainRoutes />}>
          <Route index element={<Home />} />
          <Route path="product" element={<Pricing />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="share/:paletteId" element={<SharedPalette />} />
          <Route path="paymentSuccess" element={<PaymentSuccess />} />
          <Route path="search" element={<SearchTool />} />
          <Route element={<AuthRoutes />}>
            <Route path="login" element={<Login />} />
            {/* <Route path="signUp" element={<SignUp />} /> */}
            <Route path="resetPassword" element={<PasswordReset />} />
            <Route path="createAccount" element={<SignUp />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="colormatch/:paletteId?" element={<Tool />} />
            <Route path="hexamatch" element={<HexaTool />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/palettes" element={<MyPalettes />} />
            <Route element={<AdminRoutes />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="dashboard/balloons" element={<Balloons />} />
              <Route path="dashboard/accessories" element={<Accessories />} />
              <Route path="dashboard/users" element={<Users />} />
              <Route path="dashboard/settings" element={<Settings />} />
              <Route
                path="dashboard/uploadedImages"
                element={<UploadedImages />}
              />
              <Route
                path="dashboard/hexDistanceCalc"
                element={<HexDistanceCalculator />}
              />
              <Route
                path="dashboard/featuredBalloons"
                element={<FeaturedBalloons />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
